import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon,
} from "@mui/material";

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import AddCardIcon from '@mui/icons-material/AddCard';
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import StraightIcon from '@mui/icons-material/Straight';

import { Transaction } from "./models";

const styles = {
  listItemText: {
    margin: 0,
  },
  underline: {
    borderBottom: "thin solid rgba(0, 0, 0, 0.12)",
    paddingBottom: 10,
  },
  icon: {
    alignSelf: "baseline",
    marginTop: "0.5em",
  },
  refundIcon: {
    position: "absolute" as const,
    top: 50,
  }
}

const TransactionCard: React.FC<{ transaction: Transaction }> = ({
  transaction,
}) => {
  const getStatusIcon = (status: string) => {
    switch (status) {
      case "approved":
        return <ShoppingCartCheckoutIcon color="success" />;
      case "insufficientFunds":
        return <CancelOutlinedIcon color="error" />;
      case "refundIssued":
        return <AddCardIcon color="success" />;
      case "transferCanceled":
        return <CancelOutlinedIcon color="error" />;
      default:
        return <Icon>help_outline</Icon>;
    }
  };

  return (
    <ListItem>
      <ListItemIcon style={styles.icon}>{getStatusIcon(transaction.status)}</ListItemIcon>

      {transaction.status == "refundIssued" && <StraightIcon style={styles.refundIcon} color="success" />}

      <ListItemText sx={{
        "&.MuiListItemText-root": styles.listItemText
      }}
        secondaryTypographyProps={{ style: styles.underline }}
        primary={transaction.description}
        secondary={`${transaction.amount.toFixed(2)}`}
      />
    </ListItem>
  );
};

export default TransactionCard;
