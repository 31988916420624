import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  items: { question: string; answer: React.ReactNode }[];
}

function FAQ({ title, items }: Props) {
  return (
    <Box sx={{ my: 2, marginLeft: "8px", marginRight: "8px" }}>
      <Typography variant="h6" mb={1}>
        {title}
      </Typography>
      {items.map((item, index) => {
        return (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              //   aria-controls="panel1-content"
              id={`${index}`}
            >
              {item.question}
            </AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}

export default FAQ;
