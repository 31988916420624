import React from "react";
import { Divider, List, ListItem, ListSubheader } from "@mui/material";
import { cardV2faqData, cardV1faqData } from "./models";
import { Link, useParams } from "react-router-dom";
import { Version } from "../../api/api";
import { useTranslation } from "react-i18next";

const CardFAQsPage: React.FC = () => {
  const { version } = useParams<{ version: string }>();
  const ver: Version = version === "1" ? "v1" : "v2";
  const datasource = ver === "v1" ? cardV1faqData : cardV2faqData;
  const { t } = useTranslation();

  return (
    <List
      sx={{ backgroundColor: "white", height: "calc(100vh - 56px - 32px)" }}
    >
      <ListSubheader>{t("card_faq_description")}</ListSubheader>

      {datasource.map((item, index) => (
        <>
          {index !== 0 && <Divider variant="middle" sx={{ my: 1 }} />}

          <ListItem key={index}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/card/${version}/faq/${index}`}
            >
              {t(item.question)}
            </Link>
          </ListItem>
        </>
      ))}
    </List>
  );
};

export default CardFAQsPage;
