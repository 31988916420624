import React from "react";
import i18n from "i18next";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { isValidNumberForRegion, CountryCode } from "libphonenumber-js";

import { AvailablePaymentMethod, PaymentMethodProp, PropertyClientType } from "../../types/paymentTypes";
import { ValidationResponse, ValidationStatus } from "../ValidationTypes";

type FormPropertyProps = {
    paymentMethod: AvailablePaymentMethod,
    property: PaymentMethodProp,
    propertyPath: string;
    formState: Record<string, string>,
    errors: { [key: string]: string },
    isDisabled?: boolean,
    handleInputChange: (propertyPath: string, property: any) => (event: any) => void;
};

const FormProperty: React.FC<FormPropertyProps> = ({ paymentMethod, property, propertyPath, formState, errors, isDisabled = false, handleInputChange }) => {
    const isNestedPropertyRequired = (propertyPath: string) => {
        const pathParts = propertyPath.split(".");

        return pathParts.length > 1;
    };

    const isRequired = paymentMethod?.required?.includes(property.property_name) || isNestedPropertyRequired(propertyPath);

    // save entered phone value without modification and update the form state
    const handlePhoneInputChange = (propertyPath: string, property: PaymentMethodProp, newValue: string) => {
        handleInputChange(propertyPath, property)({ target: { value: newValue } });
    };

    // return input type based on property client_type
    const determineInputType = (clientType: string | undefined | null) => {
        switch (clientType) {
            case PropertyClientType.Number:
                return "number";
            case PropertyClientType.Email:
                return "email";
            case PropertyClientType.Date:
                return "date";
            default:
                return "text";
        }
    };

    if (property.client_type === PropertyClientType.Phone) {
        return (
            <>
                <MuiTelInput
                    data-test-id="form-phone"
                    fullWidth
                    forceCallingCode
                    disableDropdown
                    defaultCountry={paymentMethod.country as CountryCode}
                    onlyCountries={[paymentMethod.country as CountryCode]}
                    value={formState[propertyPath]}
                    label={property.title}
                    helperText={errors[propertyPath]}
                    required={isRequired}
                    error={!!errors[propertyPath]}
                    onChange={(value, info) => {
                        handlePhoneInputChange(propertyPath, property, value);
                    }}
                />
            </>
        );
    }

    if (property.enum) {
        return (
            <>
                <FormControl
                    data-test-id={property.property_name}
                    fullWidth
                    sx={{ textAlign: "left", pl: 1, fontSize: "0.875rem" }}
                    component="fieldset"
                    required={isRequired}
                >
                    <FormLabel component="legend">{property.title}</FormLabel>

                    <RadioGroup
                        data-test-id={property.property_name + "-radio"}
                        id={propertyPath}
                        value={formState[propertyPath] || ""}
                        onChange={handleInputChange(propertyPath, property)}
                    >
                        {property.enum.map((item, index) => (
                            <FormControlLabel
                                data-test-id={item.value + "-label"}
                                key={index}
                                value={item.key}
                                control={<Radio />}
                                label={
                                    <Typography fontSize="0.875rem">{item.value}</Typography>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </>
        )
    }

    return (
        <>
            <style>
                {`
                  .numeric-input::-webkit-inner-spin-button,
                  .numeric-input::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  .numeric-input {
                    -moz-appearance: textfield;
                  }
                  .MuiFormLabel-asterisk, .MuiInputLabel-asterisk {
                    display: none;
                  }              
                `}
            </style>

            <TextField
                fullWidth
                id={property.property_name}
                data-test-id={property.property_name}
                type={determineInputType(property.client_type)}
                value={formState[propertyPath] || ""}
                onChange={handleInputChange(propertyPath, property)}
                disabled={isDisabled}
                label={property.title}
                required={isRequired}
                InputLabelProps={{ shrink: formState[propertyPath] || property.client_type === PropertyClientType.Date ? true : false }}
                inputProps={{
                    minLength: property.minLength,
                    maxLength: property.maxLength,
                    className: property.client_type === PropertyClientType.Number ? "numeric-input" : undefined
                }}
                helperText={errors[propertyPath]}
                error={!!errors[propertyPath]}
                onKeyPress={(event) => {
                    if (property.client_type === PropertyClientType.Number && /[^0-9]/.test(event.key)) {
                        event.preventDefault();
                    }
                }}
            />

            {property.description && (
                <Typography variant="body2" color="text.secondary" marginTop={1}>
                    {property.description}
                </Typography>
            )}
        </>
    );
};

export default FormProperty;


export const validateProperty = (
    property: PaymentMethodProp,
    value: string,
    countryCode: string,
): ValidationResponse => {
    const { minLength, maxLength, pattern, title } = property;

    if (property.client_type === PropertyClientType.Phone) {
        if (!isValidNumberForRegion(value, countryCode as CountryCode)) {
            return {
                status: ValidationStatus.NOT_VALID,
                errorMessage: i18n.t("error_invalidPhone"),
            };
        } else {
            return {
                status: ValidationStatus.VALID,
                errorMessage: "",
            };
        }
    }

    if (pattern) {
        const regex = new RegExp(pattern);

        if (!regex.test(value)) {
            return {
                status: ValidationStatus.NOT_VALID,
                errorMessage: i18n.t("error_invalidValue", {
                    title: title
                }),
            };
        }
    }

    if (maxLength && value.length > maxLength) {
        return {
            status: ValidationStatus.NOT_VALID,
            errorMessage: i18n.t("error_valueExceedsMax", {
                title: title,
                maxLength: maxLength,
            }),
        };
    } else if (minLength && value.length < minLength && value.length > 0) {
        if (minLength === maxLength) {
            return {
                status: ValidationStatus.NOT_VALID,
                errorMessage: i18n.t("error_valueExactLength", {
                    title: title,
                    maxLength: maxLength,
                }),
            };
        }

        return {
            status: ValidationStatus.NOT_VALID,
            errorMessage: i18n.t("error_valueBetweenMinMax", {
                title: title,
                minLength: minLength,
                maxLength: maxLength,
            }),
        };
    }

    // Return VALID status if no validation rule is violated
    return {
        status: ValidationStatus.VALID,
        errorMessage: "",
    };
};