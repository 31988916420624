import React from "react";
import { Container, Typography, Box } from "@mui/material";
import ManageCard from "./ManageCard";
import TransactionList from "./TransactionList";
import { transactions } from "./models";
import CardAlert from "./CardAlert";

const CardPage: React.FC = () => {
  return (
    <Box>
      {/* WE NEED A BE CALL TO KNOW IF WE HAVE NEW CARDS
      CURRENTLY /v1 CALLS BREAK THE APP */}
      {/* <CardAlert text="$00.01 available on your old card" /> */}

      <ManageCard />

      {/* <Box sx={{ marginTop: 4 }}>
        <Typography variant="h6">Card activity</Typography>
        <TransactionList transactions={transactions} />
      </Box> */}
    </Box>
  );
};

export default CardPage;
