import React, { useEffect, useState } from "react";
import { Box, List, ListItem, Skeleton } from "@mui/material";

import HtmlVccRenderer from "../components/VccCard/HtmlVccRenderer";
import VccCard from "../components/VccCard/VccCard";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { useErrorBoundary } from "react-error-boundary";
import FAQ from "../components/FQA";
import { useTranslation } from "react-i18next";

const VccVisualise: React.FC = () => {
  const [htmlCode, setHtmlCode] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();

  const isVccCreation = window.location.pathname === "/vcc-create";

  useEffect(() => {
    setLoading(true);

    api(paymentService.getVccPaymentVisualise())
      .then((response) => {
        setHtmlCode(response);
      })
      .catch((error) => {
        showBoundary(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { t } = useTranslation();
  const faqItems = [
    {
      question: t(`vcc_faq_question1`),
      answer: t(`vcc_faq_answer1`),
    },
    {
      question: t(`vcc_faq_question2`),
      answer: (
        <List
          sx={{
            py: 0,
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          {Array.from({ length: 7 }, (_, index) => {
            return (
              <ListItem sx={{ px: 1 }}>
                {t(`vcc_faq_answer2_p${index + 1}`)}
              </ListItem>
            );
          })}
        </List>
      ),
    },
    {
      question: t(`vcc_faq_question3`),
      answer: (
        <List
          sx={{
            py: 0,
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          {Array.from({ length: 7 }, (_, index) => {
            return (
              <ListItem sx={{ px: 1 }}>
                {t(`vcc_faq_answer3_p${index + 1}`)}
              </ListItem>
            );
          })}
        </List>
      ),
    },
  ];

  return (
    <Box width="100%">
      {loading ? (
        <>
          <Skeleton
            variant="rounded"
            height={184}
            style={{ marginBottom: 16 }}
          />

          {!isVccCreation && <Skeleton variant="rounded" height={72} />}
        </>
      ) : (
        <>
          {htmlCode && <HtmlVccRenderer htmlCode={htmlCode?.data} />}
          {htmlCode && !isVccCreation && <VccCard />}
          {!isVccCreation && <FAQ title="About your card" items={faqItems} />}
        </>
      )}
    </Box>
  );
};

export default VccVisualise;
