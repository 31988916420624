import { useState, useEffect } from "react";
import {
  decryptionService,
  decryptionTokenService,
} from "../api/decryptionService";
import { api, evervaultAPI } from "../api/api";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/browser";

type DecryptedIdAndLoading = {
  decryptedId: string | null;
  decryptionLoading: boolean;
  decryptionError: AxiosError | null;
};

const cache: { [key: string]: string } = {};

const useDecrypt = (
  target_account_identifier: string | null | undefined,
  deps: any[] = [],
  showLatestFourDigits: boolean = true,
  shouldDecrypt: boolean = true
): DecryptedIdAndLoading => {
  const [decryptedId, setDecryptedId] = useState<string | null>(null);
  const [decryptionLoading, setDecryptionLoading] = useState(false);
  const [decryptionError, setDecryptionError] = useState<AxiosError | null>(
    null
  );

  const fetchAndDecrypt = async (identifier: string) => {
    try {
      const { data: tokenData } = await api(decryptionTokenService(identifier));
      const token = tokenData.token;
      const { data: decryptedData } = await evervaultAPI(
        decryptionService(identifier, token)
      );
      return decryptedData.result.target_account_identifier;
    } catch (error) {
      const err = error as AxiosError;
      setDecryptionError(err);
      throw error;
    }
  };

  useEffect(() => {
    if (!target_account_identifier || !shouldDecrypt) {
      setDecryptedId(target_account_identifier ?? "");
      return;
    }

    const getCachedOrDecrypt = async () => {
      let decryptedValue = cache[target_account_identifier];
      if (decryptedValue) {
        setDecryptedId(decryptedValue);
      } else {
        setDecryptionLoading(true);
        try {
          decryptedValue = await fetchAndDecrypt(target_account_identifier);
          if (decryptedValue) {
            const finalValue = showLatestFourDigits
              ? decryptedValue.slice(-4)
              : decryptedValue;
            cache[target_account_identifier] = finalValue;
            setDecryptedId(finalValue);
          }
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setDecryptionLoading(false);
        }
      }
    };

    getCachedOrDecrypt();
  }, deps);

  return { decryptedId, decryptionLoading, decryptionError };
};

export default useDecrypt;
