import React, { useEffect } from "react";
import OnboardingComponent, {
  OnboardingDataItem,
} from "./components/OnboardingComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../api/api";
import { getVirtualCard } from "../../api/paymentMethodsService";
import OnboardingComponentSkeleton from "./components/OnboardingComponentSkeleton";
import CardViewer from "./components/CardViewer";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { Box } from "@mui/system";
import { VirtualCardResponse } from "../../types/paymentTypes";
import {
  formatNumber,
  formatNumberWithoutCurrency,
} from "../../common/helpers";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/slices/toastSlice";
import { RootState } from "../../redux/store";
import { selectCurrencySymbol } from "../../redux/slices/paymentMethodsSlice";
import { CardProvider } from "./components/CardContext";

const TwoCardsSuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  const {
    loading: oldCardLoading,
    error: oldCardError,
    data: oldCardData,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard("v1"));

  const navigate = useNavigate();
  function getData(): OnboardingDataItem[] {
    const oldCardBalance =
      (oldCardData ?? []).length > 0 ? oldCardData![0].balance : 0;

    return [
      {
        title: t("cardOnboarding_title_newCardReady"),
        description: [t("cardOnboarding_description_newCard")],
        imageSrc: () => (
          <Box
            sx={{
              objectFit: "contain",
              backgroundColor: "lightGray",
              height: "229.5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CreditScoreIcon
              color="primary"
              sx={{ fontSize: 120 }}
              width="100%"
            />
          </Box>
        ),
      },
      {
        title: t("cardOnboarding_title_oldCard"),
        description: [
          t("cardOnboarding_description_oldCard_1"),
          t("cardOnboarding_balance", {
            balance: formatNumberWithoutCurrency(oldCardBalance),
            currency: "USD",
          }),
          t("cardOnboarding_description_oldCard_3"),
        ],
        imageSrc: () => (
          <CardProvider>
            <CardViewer key="v1" version="v1" />
          </CardProvider>
        ),
      },
      {
        title: t("cardOnboarding_title_newCard"),
        description: [
          t("cardOnboarding_description_newCard_1"),
          t("cardOnboarding_balance", { balance: "00.00", currency: "USD" }),
          t("cardOnboarding_description_newCard_3"),
        ],
        imageSrc: () => (
          <CardProvider>
            <CardViewer key="v2" version="v2" />
          </CardProvider>
        ),
      },
    ];
  }

  const onFinish = () => {
    dispatch(showToast(t("paymentMethod_updated")));
    navigate("/payments");
  };

  useEffect(() => {
    if (!oldCardData) return;
  }, [oldCardData, t]);

  if (oldCardLoading) {
    return <OnboardingComponentSkeleton cardMediaHeight={229.5} />;
  }

  return (
    <OnboardingComponent
      data={getData()}
      finishLabel={t("finish")}
      onFinish={onFinish}
    />
  );
};

export default TwoCardsSuccessPage;
