import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Alert, AlertColor } from "@mui/material";

interface Props {
  icon?: ReactElement;
  title: string;
  subtitle?: string | null;
  description: string;
  color?: string;
  backgroundColor?: string;
  severity?: AlertColor;
  linkTo: string;
  showRightChevron?: boolean;
}

const StipendCard = ({
  icon,
  title,
  subtitle,
  description,
  color,
  backgroundColor,
  severity,
  linkTo,
  showRightChevron = true,
}: Props) => {
  const style: React.CSSProperties = {
    marginBottom: "10px",
    padding: "10px",
    border: "none",
    backgroundColor: "transparent",
  };

  if (color) {
    style.color = color;
  }

  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }

  return (
    <Link
      data-test-id="payment-container"
      to={linkTo}
      style={{ textDecoration: "none" }}
    >
      <Alert
        severity={severity}
        icon={
          icon ? (
            <ListItemIcon
              data-test-id="payment-icon"
              style={color ? { color, minWidth: "20px" } : {}}
            >
              {icon}
            </ListItemIcon>
          ) : undefined
        }
        style={style}
        action={
          showRightChevron ? (
            <ChevronRightIcon
              data-test-id="payment-right-chevron"
              style={color ? { color } : {}}
            />
          ) : null
        }
      >
        <div>
          <Typography
            variant="body1"
            data-test-id="payment-title"
            style={
              color
                ? { color, fontWeight: "bold", marginBottom: "8px" }
                : { fontWeight: "bold", marginBottom: "8px" }
            }
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              data-test-id="payment-subtitle"
              variant="body2"
              style={
                color
                  ? { color, overflowWrap: "anywhere" }
                  : { overflowWrap: "anywhere" }
              }
            >
              {subtitle}
            </Typography>
          )}
          <Typography
            data-test-id="payment-description"
            variant="body2"
            style={color ? { color } : {}}
          >
            {description}
          </Typography>
        </div>
      </Alert>
    </Link>
  );
};

export default StipendCard;
