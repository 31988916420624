import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/global.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { ThemeProvider } from "@mui/material/styles"; // Import the ThemeProvider
import { EvervaultProvider } from "@evervault/react";
import theme from "./theme"; // Import your custom theme
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // /^http:\/\/localhost:300/,
        /^https:\/\/velocity\.nmible-staging\.com/,
        /^https:\/\/velocity\.nmible\.net/,
        /^https:\/\/trialkit\.nmible-staging\.com/,
        /^https:\/\/trialkit\.nmible\.net/,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <EvervaultProvider
        teamId={process.env.REACT_APP_EVERVAULT_TEAM}
        appId={process.env.REACT_APP_EVERVAULT_APP}
      >
        <ThemeProvider theme={theme}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </ThemeProvider>
      </EvervaultProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
