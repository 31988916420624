import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import LockIcon from "@mui/icons-material/Lock";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OnboardingComponent, {
  OnboardingDataItem,
} from "./components/OnboardingComponent";

const CardBankOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: OnboardingDataItem[] = [
    {
      title: t("Move money to you bank"),
      features: [
        {
          icon: <TimelapseIcon />,
          description: t("Transfers take 3-5 business days"),
        },
        {
          icon: <BeachAccessIcon />,
          description: t("Weekends and holidays may delay arrival"),
        },
        {
          icon: <LockIcon />,
          description: t("Once sent, transfers can't be cancelled"),
        },
      ],
      imageSrc: "/card/doubt.svg",
    },
  ];

  const onFinish = () => {
    navigate("/payment-methods/BANK_ACCOUNT?transfer=true");
  };

  return (
    <OnboardingComponent
      data={data}
      finishLabel={t("next")}
      onFinish={onFinish}
    />
  );
};

export default CardBankOnboardingPage;
