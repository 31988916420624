import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import Error from "../components/Error";
import ServerWarningSVG from "../assets/server_warning.svg";
import UpdatedDocumentsSVG from "../assets/updated_documents.svg";
import NoConnectionSVG from "../assets/no_connection.svg";
import UnauthorisedSVG from "../assets/unauthorised.svg";
import { useLocation, useNavigate } from "react-router-dom";
import StorageManager from "../services/storage";
import { TFunction } from "i18next";
import * as Sentry from "@sentry/browser";

interface CustomErrorBoundaryProps {
  children: React.ReactNode;
  onCatch?: (hasError: boolean) => void;
}

export enum ErrorType {
  UNAUTHORIZED = "UNAUTHORIZED",
  TERMS_UPDATED = "TERMS_UPDATED",
  TERMS_NAVIGATE = "TERMS_NAVIGATE",
  NO_CONNECTION = "NO_CONNECTION",
  RUNTIME = "RUNTIME",
  GENERAL = "GENERAL",
}

interface TermsErrorResponse {
  detail?: {
    first_time_user?: boolean;
  };
}

interface ErrorProps {
  title: string;
  message: string;
  statusCode?: number;
  icon: string;
  buttonText: string;
  isRefreshBtn?: boolean;
}
interface FallbackProps {
  error: any;
  resetErrorBoundary: () => void;
}

export interface ErrorDetails {
  type: ErrorType;
  props: ErrorProps;
}

interface ErrorResponseData {
  detail?: { message?: string; msg?: string }[];
}

const getErrorType = (error: AxiosError): ErrorType => {
  const statusCode = error.response?.status;
  if (statusCode === 401 || statusCode === 403) {
    return ErrorType.UNAUTHORIZED;
  }
  if (statusCode === 409) {
    let termsError = error.response?.data as TermsErrorResponse;
    let isFirstTimeUser = termsError.detail?.first_time_user;
    return isFirstTimeUser === true
      ? ErrorType.TERMS_NAVIGATE
      : ErrorType.TERMS_UPDATED;
  }

  const cantConnectStatusCodes = [599, 522, 524, 523, 503, 408];
  if (!error.response || cantConnectStatusCodes.includes(statusCode ?? -1)) {
    return ErrorType.NO_CONNECTION;
  }

  return ErrorType.GENERAL;
};

export const getErrorDetails = (
  error: AxiosError,
  t: TFunction
): ErrorDetails | undefined => {
  let statusCode: number | undefined;
  let message: string | undefined;

  const method = error.config?.method;
  statusCode = error.response?.status;
  const errorType = getErrorType(error);

  const detailsArray = (error.response?.data as ErrorResponseData)?.detail;
  if (detailsArray && Array.isArray(detailsArray)) {
    message = detailsArray
      ?.map((detail) => detail.message || detail.msg)
      .join("\n");
  }
  if (errorType === ErrorType.UNAUTHORIZED) {
    return {
      type: errorType,
      props: {
        title: t("error_unauthorized_title"),
        message: t("error_unauthorized_message"),
        statusCode: statusCode,
        icon: UnauthorisedSVG,
        buttonText: t("tryAgain"),
      },
    };
  }

  if (
    errorType === ErrorType.TERMS_NAVIGATE ||
    errorType === ErrorType.TERMS_UPDATED
  ) {
    return {
      type: errorType,
      props: {
        title: t("error_termsUpdated_title"),
        message: t("error_termsUpdated_message"),
        icon: UpdatedDocumentsSVG,
        isRefreshBtn: false,
        buttonText: t("reviewTerms"),
      },
    };
  }

  if (errorType === ErrorType.NO_CONNECTION) {
    return {
      type: errorType,
      props: {
        title: t("error_connection_title"),
        message: t("error_connection_message"),
        statusCode: statusCode,
        icon: NoConnectionSVG,
        buttonText: t("tryAgain"),
      },
    };
  }

  return {
    type: errorType,
    props: {
      title: message ? t("error") : t("error_general_title"),
      message: message ?? t("error_general_message"),
      statusCode: statusCode,
      icon: ServerWarningSVG,
      buttonText: t("tryAgain"),
    },
  };
};

const CustomErrorBoundary: React.FC<CustomErrorBoundaryProps> = ({
  children,
  onCatch,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const errorHandler = (error: unknown): ErrorDetails | undefined => {
    if (error instanceof AxiosError) {
      const details = getErrorDetails(error, t);
      const method = error.config?.method;
      if (details?.type === ErrorType.TERMS_NAVIGATE) {
        navigate("/terms-and-conditions", { replace: true });
        return;
      }

      return details;
    }
  };

  const buttonAction = (
    errorType: ErrorType,
    resetErrorBoundary: () => void
  ): (() => void) => {
    switch (errorType) {
      case ErrorType.TERMS_UPDATED:
        return () => navigate("/terms-and-conditions", { replace: true });
      default:
        return () => {
          if (onCatch) onCatch(false);
          resetErrorBoundary();
        };
    }
  };

  const FallbackComponent: React.FC<FallbackProps> = ({
    error,
    resetErrorBoundary,
  }) => {
    const errorDetails = errorHandler(error);
    if (!errorDetails) return null;

    const { type, props } = errorDetails;
    return (
      <Error {...props} buttonAction={buttonAction(type, resetErrorBoundary)} />
    );
  };
  return (
    <ErrorBoundary
      key={location.pathname}
      FallbackComponent={FallbackComponent}
      onError={(error, info) => {
        if (onCatch) {
          onCatch(true);
        }
        const errorType =
          error instanceof AxiosError ? getErrorType(error) : ErrorType.RUNTIME;
        if (
          errorType !== ErrorType.TERMS_NAVIGATE &&
          errorType !== ErrorType.TERMS_UPDATED
        ) {
          console.error("Report the error", error, info);
          Sentry.captureException(error, {
            extra: {
              errorInfo: info,
            },
          });
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
