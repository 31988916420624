import { Box, Button, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import paymentService from "../../../api/paymentService";
import HtmlVccRenderer from "../../../components/VccCard/HtmlVccRenderer";
import { api, Version } from "../../../api/api";
import { useCardContext } from "./CardContext";

const CardViewer: React.FC<{ version: Version }> = ({ version }) => {
  const [htmlCode, setHtmlCode] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const { iframeRef } = useCardContext();

  useEffect(() => {
    setLoading(true);
    api(paymentService.getVccPaymentVisualise(version))
      .then((response) => {
        setHtmlCode(response);
        if (iframeRef.current) {
          iframeRef.current.src = response.data;
        }
      })
      .catch((error) => {
        showBoundary(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        aspectRatio: 1.55,
      }}
    >
      {loading ? (
        <Skeleton
          variant="rounded"
          height={"100%"}
          style={{ marginBottom: 16 }}
        />
      ) : (
        <Box>
          {htmlCode?.data && <HtmlVccRenderer htmlCode={htmlCode?.data} />}
        </Box>
      )}
    </Box>
  );
};

export default CardViewer;
