import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import { Card, Divider, List, Typography } from "@mui/material";
import {
  convertDateToFormattedString,
  groupAndSortByDate,
} from "../../common/helpers";
import { Payment, PaymentStatus } from "../../types/stipendTypes";
import { getStatusText } from "../Stipend/StipendCommon";
import TransactionItem from "./TransactionItem";
import React from "react";
import { useTranslation } from "react-i18next";

interface PaymentsListProps {
  payments: Payment[];
}

const PaymentsList: React.FC<PaymentsListProps> = ({ payments }) => {
  const groupedPayments = groupAndSortByDate(payments, "action_on");
  const { t } = useTranslation();

  function getIcon(status: PaymentStatus): React.ReactNode {
    switch (status) {
      case PaymentStatus.SENT:
        return <CheckCircleOutlineIcon color="action" />;
      case PaymentStatus.MISSING_PAYMENT_DETAILS:
      case PaymentStatus.INVALID_PAYMENT_ACCOUNT:
        return <HighlightOffIcon color="action" />;
      default:
        return <InfoOutlinedIcon color="action" />;
    }
  }
  return (
    <Card variant="outlined" sx={{ border: "none" }}>
      <List disablePadding>
        {Object.keys(groupedPayments).map((date) => (
          <React.Fragment key={date}>
            <Typography
              variant="subtitle2"
              fontWeight="600"
              color="text.secondary"
              sx={{ ml: 2, mt: 2 }}
            >
              {date}
            </Typography>
            {groupedPayments[date].map((payment, index) => {
              const descriptions: string[] = [];

              const statusDescription = getStatusText(payment.status);
              descriptions.push(statusDescription);

              if (payment.payment_reason)
                descriptions.push(payment.payment_reason);

              return (
                <React.Fragment key={payment.transaction_id}>
                  {index !== 0 && <Divider sx={{ ml: 6 }} />}
                  <TransactionItem
                    key={payment.transaction_id}
                    icon={getIcon(payment.status)}
                    title={t("deposit_received")}
                    description={descriptions}
                    amount={payment.amount}
                    linkTo={`/payment/${payment.transaction_id}`}
                  />
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ))}
      </List>
    </Card>
  );
};

export default PaymentsList;
