import {
  Box,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import React from "react";

const OnboardingComponentSkeleton: React.FC<{ cardMediaHeight: number }> = ({
  cardMediaHeight,
}) => {
  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 56px - 32px)",
      }}
    >
      <Skeleton
        variant="rectangular"
        width="100%"
        height={cardMediaHeight}
        animation="wave"
      />
      <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
        <Skeleton width="30%" height={20} style={{ marginBottom: 6 }} />
        <Skeleton width="60%" height={28} style={{ marginBottom: 10 }} />
        <Skeleton width="100%" height={20} style={{ marginBottom: 6 }} />
        <Skeleton width="60%" height={20} style={{ marginBottom: 10 }} />

        <Skeleton width="100%" height={20} style={{ marginBottom: 6 }} />
        <Skeleton width="100%" height={20} style={{ marginBottom: 6 }} />
        <Skeleton width="30%" height={20} />
      </CardContent>
      <Box
        sx={{
          flexGrow: 0,
          p: 2,
          width: "100%",
          borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Skeleton width={90} height={36} />
          <Skeleton width={90} height={36} />
        </CardActions>
      </Box>
    </Card>
  );
};

export default OnboardingComponentSkeleton;
