import React from "react";

import { ErrorDetails } from "../../common/CustomErrorBoundary";
import ErrorBox from "../Modal/ErrorBox";

type FormErrorBoxProps = {
    error: ErrorDetails | null
};

const FormErrorBox: React.FC<FormErrorBoxProps> = ({ error }) => {

    if (!error) {
        return null;
    }

    return (
        <ErrorBox
            title={error.props.title}
            text={error.props.message}
            marginBottom="20px"
        />
    );
};

export default FormErrorBox;
