import { PaymentType } from "./paymentTypes";

export interface VccCardType {
  target_account_id: string;
  card_number: string;
  balance: number;
}

export interface CardData extends VccCardType {
  type: PaymentType.VirtualCardV1 | PaymentType.VirtualCardV2;
}

export interface TransformedVccCardType {
  target_account_id: string;
  target_account_type: string;
  target_account_identifier: string;
  target_account_title: string;
  balance?: number;
}

export interface UserProfile {
  participant_number: string;
  claimant_id: string;
  country: string;
}

export interface Visit {
  name: string;
  window: number;
  timeframe: number;
  is_screening: boolean;
  is_randomisation: boolean;
  description: string;
  id: number;
  is_discontinued: boolean;
  earliest_date: string;
  latest_date: string;
  confirmed_date: string | null;
}

export interface Token {
  access_token: string;
  refresh_token: string;
  country_iso?: string;
}

export const cleanEmptyProperties = (obj: any, ignoreKey: string = "") => {
  let cleanedObj = Object.entries(obj)
    .filter(([key, value]) => {
      return (
        key !== ignoreKey &&
        value !== null &&
        value !== undefined &&
        value !== ""
      );
    })
    .reduce((acc: any, [key, value]) => ({ ...acc, [key]: value }), {});

  return cleanedObj;
};
