// import { api } from './api'; // Import the new api object
// import { DECRYPTED_PAYMENT_TOKEN_URL, EVERVAULT_GET_NEW_TOKEN_URL } from './config';
// import { succesStatus } from '../common/constants';
import { AxiosRequestConfig } from "axios";

// export const decryptionRunTokenRequest = async (accIdentifier: any): Promise<string> => {
//   const response = await api.post(DECRYPTED_PAYMENT_TOKEN_URL, {
//     target_account_identifier: accIdentifier,
//   });
//   return response.data.token;
// };

// export const evervaultRequest = async (payload: any, decryptionToken: string): Promise<string> => {
//   // Assuming the new api object is set up to use decryptionToken via its interceptors
//   const { data } = await api.post(EVERVAULT_GET_NEW_TOKEN_URL, payload);
//   return data.result.info;
// };

// export const getDecryptedEvnValue = async (accIdentifier: any, decryptionToken: string): Promise<string> => {
//   // Assuming the new api object is set up to use decryptionToken via its interceptors
//   const response = await api.post(EVERVAULT_GET_NEW_TOKEN_URL, {
//     target_account_identifier: accIdentifier,
//   });
//   if (succesStatus.includes(response.status)) {
//     return response.data.result.target_account_identifier;
//   } else {
//     throw new Error("Evervault request failed");
//   }
// };

export const decryptionTokenService = (
  accIdentifier: string
): AxiosRequestConfig => {
  return {
    url: "/v1/payments/decryption-run-token",
    method: "POST",
    data: {
      target_account_identifier: accIdentifier,
    },
  };
};

export const decryptionService = (
  accIdentifier: string,
  token: string
): AxiosRequestConfig => {
  return {
    url: process.env.REACT_APP_EVERVAULT_FUNCTION,
    method: "POST",
    data: {
      target_account_identifier: accIdentifier,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
