import React, { useEffect, useState } from "react";
import {
  ComputerOutlined,
  ContactlessOutlined,
  MoveToInboxOutlined,
  TimelineOutlined,
  AccountBalanceOutlined,
  LockOpenOutlined,
  CreditCardOff,
  Timer,
  SyncDisabled,
  Home,
  LocalPhone,
  Email,
  Security,
} from "@mui/icons-material";
import OnboardingComponent, {
  OnboardingDataItem,
} from "./components/OnboardingComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPI } from "../../api/api";
import { getVirtualCard } from "../../api/paymentMethodsService";
import Skeleton from "@mui/material/Skeleton";
import OnboardingComponentSkeleton from "./components/OnboardingComponentSkeleton";
import { Avatar, Typography } from "@mui/material";
import { PaymentType } from "../../types/paymentTypes";

const CardOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: OnboardingDataItem[] = [
    {
      // This is the initial item that will be replaced based on API results
      title: t("cardOnboarding_page1_title"),
      description: [t("cardOnboarding_page1_description")],
      imageSrc: "/card/visa-card.svg",
    },
    {
      title: t("cardOnboarding_page2_title"),
      features: [
        {
          icon: <ComputerOutlined />,
          description: t("cardOnboarding_page2_shop_online"),
        },
        {
          icon: <ContactlessOutlined />,
          description: t("cardOnboarding_page2_contactless"),
        },
        {
          icon: <MoveToInboxOutlined />,
          description: t("cardOnboarding_page2_move_money"),
        },
      ],
      imageSrc: "/card/phone-shop.svg",
    },
    {
      title: t("cardOnboarding_page3_title"),
      description: [t("cardOnboarding_page3_description")],
      features: [
        {
          description: t("cardOnboarding_page3_note_1"),
        },
        {
          description: t("cardOnboarding_page3_note_2"),
        },
        {
          description: t("cardOnboarding_page3_note_3"),
        },
      ],
      footer: t("cardOnboarding_page3_footer"),
      imageSrc: "/card/mobile-chart.svg",
    },
  ];

  const onFinish = () => {
    navigate(`/payment-methods/${PaymentType.VirtualCardV2}`);
  };

  return (
    <OnboardingComponent
      data={data}
      finishLabel={t("vcc_createMyCard")}
      onFinish={onFinish}
    />
  );
};

export default CardOnboardingPage;
