import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { useCardContext } from "../../pages/Card/components/CardContext";

interface HtmlVccRendererProps {
  htmlCode: string;
}

const HtmlVccRenderer: React.FC<HtmlVccRendererProps> = ({ htmlCode }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { iframeRef } = useCardContext();

  useEffect(() => {
    const container = containerRef.current;
    if (!container || !iframeRef.current) return;

    const iframe = iframeRef.current;

    iframe.sandbox.add(
      "allow-same-origin",
      "allow-scripts",
      "allow-popups",
      "allow-forms"
    );
    iframe.style.border = "none";
    iframe.style.position = "absolute";
    iframe.style.top = "0";
    iframe.style.left = "0";
    iframe.style.width = "100%";
    iframe.style.height = "100%";

    container.appendChild(iframe);

    const doc = iframe.contentWindow?.document;
    if (doc) {
      doc.open();
      doc.write(htmlCode);
      doc.close();
    }

    return () => {
      container.removeChild(iframe);
    };
  }, [htmlCode, iframeRef]);

  return (
    <Box>
      <div
        data-test-id="vcc-iframe-container"
        style={{
          position: "relative",
          width: "100%",
          aspectRatio: 1.55,
        }}
      >
        <div
          ref={containerRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </Box>
  );
};

export default HtmlVccRenderer;
