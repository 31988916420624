import i18n from "i18next";
import moment from "moment-timezone";
import "moment/locale/es";
import "moment/locale/zh-cn";
import "moment/locale/bg";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/pt";

import { api, evervaultAPI } from "../api/api";
import {
  decryptionService,
  decryptionTokenService,
} from "../api/decryptionService";
import { Axios, AxiosInstance } from "axios";
import {
  AvailablePaymentMethod,
  PaymentMethodsResponse,
  PaymentType,
  VirtualCardResponse,
} from "../types/paymentTypes";
import {
  getPaymentMethods,
  getVirtualCard,
} from "../api/paymentMethodsService";
import { setPaymentMethodsSuccess } from "../redux/slices/paymentMethodsSlice";
import { Dispatch } from "react";

export const generateDeclinedDescription = (text: string) => {
  const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
  const emailMatch = text.match(emailRegex);

  if (emailMatch) {
    const email = emailMatch[0];
    const startIndex = text.indexOf(email);
    const endIndex = startIndex + email.length;

    return (
      <>
        {text.substring(0, startIndex)}
        {email}
        {text.substring(endIndex)}
      </>
    );
  }
  return text;
};

export async function getDecryptionValue(accIdentifier: string) {
  const tokenResponse = await evervaultAPI<TokenWrapper>(
    decryptionTokenService(accIdentifier)
  );
  const decryptedValue = await evervaultAPI(
    decryptionService(accIdentifier, tokenResponse.data.token)
  );
  return decryptedValue;
}

interface TokenWrapper {
  token: string;
}

export function formatNumberWithoutCurrency(num: number | string): string {
  const numberToFormat = typeof num === "string" ? parseFloat(num) : num;

  if (isNaN(numberToFormat)) {
    console.error(`formatNumber() - Unable to parse number from input:`, num);
    return "0.00";
  }

  const formattedNumber = numberToFormat.toFixed(2);

  return formattedNumber;
}

export function formatNumber(
  num: number | string,
  applySign: boolean = false,
  currency: string = "$"
): string {
  const numberToFormat = typeof num === "string" ? parseFloat(num) : num;

  if (isNaN(numberToFormat)) {
    console.error(`formatNumber() - Unable to parse number from input:`, num);
    return `${currency}0.00`;
  }

  const formattedNumber = numberToFormat.toFixed(2);

  if (applySign) {
    if (numberToFormat > 0) {
      return `+${currency}${formattedNumber}`;
    } else if (numberToFormat < 0) {
      return `-${currency}${Math.abs(numberToFormat).toFixed(2)}`;
    }
  }

  return `${currency}${formattedNumber}`;
}

export function convertDateToFormattedString(dateString: string): string {
  // Parse the incoming date string in UTC
  const date = moment.utc(dateString);

  // Guess the user's local timezone
  const userTimezone = moment.tz.guess();

  // Convert the date to the user's local timezone
  const localDate = date.tz(userTimezone);

  // Get the current language
  const currentLanguage = i18n.language;

  // Format the date according to the user's locale
  const formattedDate = localDate.locale(currentLanguage).format("LL"); // 'LL' is a localized date format in Moment.js

  return formattedDate;
}

export function removeSpacesAndHyphens(str: string) {
  return str.replace(/[\s-]/g, "");
}

export function stripTextAfterHyphen(text: string) {
  const hyphenIndex = text.indexOf("-");
  if (hyphenIndex === -1) {
    return text; // Return the original text if no hyphen is found
  }
  return text.substring(0, hyphenIndex); // Return the text before the hyphen
}

export function matchLocale(
  availableLocales: string[],
  preferredLocale: string
): string | undefined {
  // Exact match
  if (availableLocales.includes(preferredLocale)) {
    return preferredLocale;
  }

  // Partial match (e.g., match 'en' in 'en-US' if 'en-US' is not found)
  const generalLocale = preferredLocale.split("-")[0];
  const partialMatch = availableLocales.find(
    (locale) => locale.split("-")[0] === generalLocale
  );
  return partialMatch;
}

export function getLocalizedMessage(
  messageObject: string | undefined
): string | undefined {
  if (messageObject) {
    try {
      let messages: { [key: string]: string } = JSON.parse(messageObject);
      const currentLanguage: string = i18n.language;
      let availableLanguages = Object.keys(messages);
      let locale = matchLocale(availableLanguages, currentLanguage);
      if (locale) {
        let localizedMessage: string | undefined = messages[locale];
        return localizedMessage;
      }
    } catch (error) {
      console.error("Error parsing JSON string:", error);
      return undefined;
    }
  }

  return undefined;
}

export function enterApp(
  navigate: (path: string, options: { replace: boolean }) => void,
  api: AxiosInstance,
  dispatch: Dispatch<any>
) {
  loadPaymentMethods(dispatch, navigate, (paymentMethodsData) => {
    // const isNmibleCardAvailable =
    //   paymentMethodsData.available_payment_methods.some(
    //     (item) => item.payment_type === PaymentType.VirtualCardV2
    //   );
    const noPaymentAccount = !paymentMethodsData.latest_payment_account;
    // if didn't setup a payment account yet
    if (noPaymentAccount) {
      // if Nmible card is available as a payment method, let him create one
      // if (isNmibleCardAvailable) {
      //   navigate("/card-onboarding", { replace: true });
      //   // else, navigate to payment methods
      // } else {
      navigate("/payment-methods?isFirstTime=true", { replace: true });
      // }
    } // else if the logged in user has a payment account
    else {
      navigate("/payments", { replace: true });
    }

    // api<VirtualCardResponse[]>(getVirtualCard())
    //   .then((response) => {
    //     if (response.data.length > 0) {
    //       navigate("/payments", { replace: true });
    //     } else {
    //       navigate("/card-onboarding", { replace: true });
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response?.status === 404) {
    //       navigate("/card-onboarding", { replace: true });
    //     } else if (error.response?.status === 409) {
    //       navigate("/terms-and-conditions", { replace: true });
    //     }
    //   });
  });
}

async function loadPaymentMethods(
  dispatch: Dispatch<any>,
  navigate: (path: string, options: { replace: boolean }) => void,
  then: (paymentMethods: PaymentMethodsResponse) => void
) {
  try {
    const data = await api<PaymentMethodsResponse>(getPaymentMethods());
    const paymentMethodsData = data.data;
    savePaymentMethodsData(paymentMethodsData, dispatch);
    then(paymentMethodsData);
  } catch (error: any) {
    if (error.response?.status === 409) {
      navigate("/terms-and-conditions", { replace: true });
    }
  }
}

export function savePaymentMethodsData(
  paymentMethodsData: PaymentMethodsResponse,
  dispatch: Dispatch<any>
) {
  if (!paymentMethodsData) {
    return;
  }
  // Update property_name for all nested properties in available_payment_methods
  const updatedPaymentMethods = updateAvailablePaymentMethods(
    paymentMethodsData.available_payment_methods
  );

  const updatedPaymentMethodsData: PaymentMethodsResponse = {
    ...paymentMethodsData,
    available_payment_methods: updatedPaymentMethods,
  };

  dispatch(setPaymentMethodsSuccess(updatedPaymentMethodsData));
}

function updateAvailablePaymentMethods(
  methods: AvailablePaymentMethod[]
): AvailablePaymentMethod[] {
  // return methods.map((method) => {
  //   const updatedProperties = method.properties.map((prop) =>
  //     updatePropertyNameForChildren(prop)
  //   );
  //   return { ...method, properties: updatedProperties };
  // });
  const updatedData = methods;
  methods.forEach((paymentMethod: any) => {
    paymentMethod.properties.forEach((parentProperty: any) => {
      if (parentProperty.type == "object") {
        parentProperty.nestedProps = [];

        Object.keys(parentProperty.properties || {}).map(
          (nestedPropKey: string) => {
            parentProperty.nestedProps?.push({
              ...parentProperty?.properties![nestedPropKey],
              property_name: nestedPropKey,
            });
          }
        );
      }
    });
  });

  return updatedData;
}

export function groupAndSortByDate<T>(
  items: T[],
  dateKey: keyof T
): { [date: string]: T[] } {
  // Group items by date
  const groupedItems = items.reduce((acc, item) => {
    const dateValue = item[dateKey] as unknown as string;
    const formattedDate = convertDateToFormattedString(dateValue);
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(item);
    return acc;
  }, {} as { [formattedDate: string]: T[] });

  // Sort dates in descending order
  const sortedDates = Object.keys(groupedItems).sort((a, b) => {
    const dateA = new Date(
      groupedItems[a][0][dateKey] as unknown as string
    ).getTime();
    const dateB = new Date(
      groupedItems[b][0][dateKey] as unknown as string
    ).getTime();
    return dateB - dateA;
  });

  return sortedDates.reduce((acc, date) => {
    acc[date] = groupedItems[date];
    return acc;
  }, {} as { [date: string]: T[] });
}
