import { Alert, Divider, List, Paper } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import TitledValueListItem from "./components/TitledValueListItem";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatNumber } from "../../common/helpers";
import { selectCurrencySymbol } from "../../redux/slices/paymentMethodsSlice";
import { useNavigate } from "react-router-dom";
import { toTitleCase } from "../../components/Stipend/StipendCommon";
import { api } from "../../api/api";
import { makeTransferToBankAccount } from "../../api/paymentMethodsService";
import { showToast } from "../../redux/slices/toastSlice";
import { resetTransferAmount } from "../../redux/slices/cardSlice";

const TransferReviewPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const transferAmount =
    useSelector((state: RootState) => state.card.transferAmount) ?? 0;
  const bankAccount = useSelector((state: RootState) => state.card.cardBank);
  const currencySymbol = useSelector((state: RootState) =>
    selectCurrencySymbol(state)
  );

  function submit() {
    setLoading(true);

    api(makeTransferToBankAccount({ amount: transferAmount }))
      .then((response) => {
        dispatch(resetTransferAmount());
        dispatch(showToast(t("transfer_request_sent")));
        navigate("/payments");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!transferAmount) {
      navigate("/transfer");
      return;
    }
  }, []);

  if (!bankAccount) {
    navigate("/card/details/2");
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        minHeight: "calc(100vh - 56px - 32px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Box>
        <Alert variant="filled" severity="info">
          Please check everything is correct. Once sent, transfers can't be
          cancelled
        </Alert>
        <List>
          <TitledValueListItem
            title="Amount to transfer"
            value={formatNumber(transferAmount, false, currencySymbol)}
          />
          <Divider />
          <TitledValueListItem
            title="Account number"
            value={`**** ${bankAccount.last4}`}
          />
          <Divider />
          <TitledValueListItem
            title="Routing number"
            value={bankAccount.routingNumber}
          />
          <Divider />
          <TitledValueListItem
            title="Account type"
            value={toTitleCase(bankAccount.type)}
          />
        </List>
      </Box>
      <LoadingButton
        data-test-id="payment-methods-button"
        fullWidth
        size="large"
        variant="contained"
        onClick={submit}
        loading={isLoading}
        loadingPosition="start"
        endIcon={<KeyboardArrowRightIcon />}
      >
        <span> {t("send")}</span>
      </LoadingButton>
    </Paper>
  );
};

export default TransferReviewPage;
