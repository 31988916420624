import { AxiosInstance } from "axios";
import { loginService, refreshTokenService } from "./AuthService";
import i18n from "i18next";
import { store } from "../redux/store";
import { setTokens } from "../redux/slices/authSlice";

type FailedRequest = {
  resolve: (value?: any) => void;
  reject: (value?: any) => void;
};

let isRefreshing = false;
let failedQueue: FailedRequest[] = [];

const processQueue = (error?: any) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

export const setupInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config) => {
      const token = store.getState().auth.accessToken;
      const loginURL = loginService("").url;

      if (token && config.url !== loginURL) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      // Add the current language to Accept-Language header
      const currentLanguage = i18n.language;
      config.headers["Accept-Language"] = currentLanguage;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      const refreshURL = refreshTokenService("").url;
      const loginURL = loginService("").url;
      const isRefreshOrLogin =
        originalRequest.url.includes(refreshURL) ||
        originalRequest.url.includes(loginURL);

      if (
        error.response?.status === 401 &&
        !originalRequest._retry &&
        !isRefreshOrLogin
      ) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then(() => {
              return instance(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = store.getState().auth.refreshToken;

        if (refreshToken === null) {
          processQueue(new Error("No refresh token found"));
          return Promise.reject(error);
        }

        try {
          const response = await instance(refreshTokenService(refreshToken));
          store.dispatch(
            setTokens({
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
            })
          );

          processQueue();
          isRefreshing = false;
          return instance(originalRequest);
        } catch (err) {
          processQueue(err);
          isRefreshing = false;
          return Promise.reject(err);
        }
      }

      return Promise.reject(error);
    }
  );
};
