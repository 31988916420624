// toastSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ErrorDetails = {
  title: string;
  message: string;
};

type ToastState = {
  open: boolean;
  message: string;
  autoHide: boolean;
  error: ErrorDetails | null;
};

const initialState: ToastState = {
  open: false,
  message: "",
  autoHide: true,
  error: null,
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<ErrorDetails>) => {
      state.open = true;
      state.autoHide = true;
      state.error = action.payload;
    },
    showGlobalMessage: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.autoHide = false;
      state.message = action.payload;
    },
    showToast: (state, action: PayloadAction<string>) => {
      state.open = true;
      state.autoHide = true;
      state.message = action.payload;
    },
    hideToast: (state) => {
      state.open = false;
      state.message = "";
      state.error = null;
    },
  },
});

export const { showToast, showGlobalMessage, showError, hideToast } =
  toastSlice.actions;
export default toastSlice.reducer;
