import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
  backButtonHiddenPages,
  extraRegexPages,
  hiddenFooterPages,
  hideHeaderPages,
  sideMenuPages,
} from "../common/constants";
import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import nmible_logo from "../assets/nmible_logo.svg";
import CustomErrorBoundary from "../common/CustomErrorBoundary";
import { getTermsAndConditionsLinks } from "../common/TermsAndConditionsContentProvider";
import { setFormStep } from "../redux/slices/paymentMethodsSlice";
import { RootState } from "../redux/store";
import { configService } from "../services/configService";
import { PaymentAccountDetails } from "../types/paymentTypes";

interface ContainerProps {
  children: React.ReactNode;
}

interface HeaderItem {
  backPath?: string;
  title: string;
}

interface HeaderProps {
  [key: string]: HeaderItem;
}

const MainContainer = ({ children }: ContainerProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isErrorVisible, setErrorVisibility] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const isFirstTime = searchParams.get("isFirstTime") === "true";
  const formStep = useSelector<RootState, number>(
    (state) => state.paymentMethods.formStep
  );

  const latestPayment = useSelector<
    RootState,
    PaymentAccountDetails | null | undefined
  >((state) => state.paymentMethods.data?.latest_payment_account);

  const notifyMobileAppToClose = () => {
    if (window.webkit && window.webkit.messageHandlers) {
      // For iOS WKWebView
      window.webkit.messageHandlers.exitWebView.postMessage(null);
    } else if (window.android) {
      // For Android WebView
      window.android.exitWebView();
    }
  };

  let currentPath = location.pathname.toLowerCase().split("/");
  let path: string;

  if (currentPath[1] === "paymentMethods") {
    path = currentPath[2];
  } else {
    path = currentPath[1].replace(/-(\w)/g, function (match, letter) {
      return letter.toUpperCase();
    });
  }

  function isPathExpected(value: string, ...arrays: any) {
    for (let array of arrays) {
      if (array?.includes(value)) {
        return true;
      }
    }
    return false;
  }

  const isPresent = isPathExpected(
    path,
    backButtonHiddenPages,
    hideHeaderPages,
    sideMenuPages,
    extraRegexPages
  );

  const isFooterVisible =
    configService.config.showSupport && !hiddenFooterPages.includes(path);
  const isBackButtonHidden = backButtonHiddenPages.includes(path);

  const isHeaderVisible = !hideHeaderPages.includes(path);

  const headerProps: HeaderProps = {
    termsAndConditions: {
      backPath: "-1",
      title: "terms",
    },
    paymentMethods: {
      backPath: "-1",
      title: "paymentMethod",
    },
    payment: {
      backPath: "/payments",
      title: "paymentDetails",
    },
    helloWorld: {
      title: "Velocity",
    },
    cheque: {
      backPath: "/payment-methods",
      title: "cheque",
    },
    debit_card: {
      backPath: "/payment-methods",
      title: "debitCard",
    },
    bank_account: {
      backPath: "/payment-methods",
      title: "bankAccount",
    },
    virtual_card: {
      backPath: "/payment-methods",
      title: "vcc",
    },
    vccCard: {
      backPath: "-1",
      title: "vcc",
    },
    vccCreate: {
      title: "vcc_yourCard",
    },
    payments: {
      title: "payments",
    },
    vccPreview: {
      backPath: "/payment-methods",
      title: "vcc",
    },
    vccSetup: {
      backPath: "/vcc-preview",
      title: "vcc_setup",
    },
    vccReview: {
      backPath: "-1",
      title: "vcc",
    },
    support: {
      title: "support",
    },
    card: {
      backPath: "-1",
      title: "vcc_yourCard",
    },
    transactions: {
      backPath: "/card",
      title: "All activity",
    },
    vccCard2: {
      backPath: "-1",
      title: "vcc",
    },
    cardOnboarding: {
      title: "vcc_create",
    },
    newCardOnboarding: {
      backPath: "-1",
      title: "vcc_create",
    },
    twoCardsSuccess: {
      backPath: "-1",
      title: "vcc",
    },
    newCardSuccess: {
      title: "vcc",
    },
    transfer: {
      backPath: "-1",
      title: "Move money to your bank",
    },
    cardTransaction: {
      backPath: "-1",
      title: "Card activity",
    },
  };

  const PoweredContainer = styled(Box)({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      fontSize: "14px",
      fontWeight: "400",
    },
    "& span span": {
      textIndent: "-9999px",
      background: `url(${nmible_logo}) left no-repeat`,
      width: "52px",
      height: "15px",
      overflow: "hidden",
      marginLeft: "3px",
    },
    "& a": {
      // Styling for the "Need help?" link
      textDecoration: "underline",
      cursor: "pointer",
      fontSize: "14px",
      marginTop: "4px",
      marginBottom: "10px",
      color: "inherit",
    },
  });

  return (
    <Container disableGutters maxWidth={false}>
      {isHeaderVisible && isPresent && (
        <AppBar position="static" color="primary">
          <Toolbar sx={{ position: "relative" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!isBackButtonHidden ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    if (path === "paymentMethods" && formStep > 1) {
                      dispatch(setFormStep(formStep - 1));
                      return;
                    }
                    if (
                      path === "paymentMethods" &&
                      currentPath[currentPath.length - 1] === "payment-methods"
                    ) {
                      if (latestPayment) {
                        navigate("/payments");
                      } else {
                        notifyMobileAppToClose();
                      }
                      return;
                    }
                    if (
                      path === "payments" ||
                      path === "termsAndConditions" ||
                      isFirstTime
                    ) {
                      notifyMobileAppToClose();
                      return;
                    }
                    if (headerProps[path]?.backPath === "-1") {
                      navigate(-1);
                    } else {
                      navigate(headerProps[path]?.backPath ?? "");
                    }
                  }}
                  data-test-id="back"
                >
                  <ArrowBackIcon />
                </IconButton>
              ) : (
                <Box sx={{ width: "48px" }}></Box>
              )}
            </Box>
            <Box
              className="mainPageTitle"
              sx={{
                position: "absolute",
                left: "50%",
                minWidth: "28ch",
                transform: "translateX(-50%)",
              }}
            >
              <Typography
                data-test-id="navbar-title"
                variant="h6"
                component="h1"
                sx={{ textAlign: "center", flex: 1 }}
              >
                {headerProps[path]?.title ? t(headerProps[path].title) : null}
              </Typography>
            </Box>
            <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
              {path === "termsAndConditions" ? (
                <Button
                  data-test-id="tc-tdownload-buton"
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  href={getTermsAndConditionsLinks().pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: "white",
                    p: 0,
                    "& .MuiButton-startIcon": {
                      margin: "0 auto",
                    },
                  }}
                />
              ) : (
                <Box></Box>
              )}

              <Box></Box>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      <Grid container component="section" direction="column" p={2}>
        <CustomErrorBoundary
          onCatch={(hasError) => setErrorVisibility(hasError)}
        >
          <>{children}</>
        </CustomErrorBoundary>
      </Grid>
      {isFooterVisible && !isErrorVisible && (
        <footer>
          <PoweredContainer>
            <span data-test-id="footer-text">
              {t("poweredBy")}
              <span data-test-id="nmible"> Nmible</span>
            </span>
            <Link data-test-id="help-link" to="/support">
              <span>{t("needHelp")}</span>
            </Link>
          </PoweredContainer>
        </footer>
      )}
    </Container>
  );
};

export default MainContainer;
