import { Token } from "../types/common";

enum Keys {
  FIRST_TIME_USER = "first_time_user",
  FORM_DATA = "form_data",
  displayedGlobalMessage = "Displayed_Global_Message",
}

export default class StorageManager {
  static clear() {
    StorageManager.removeFormData();
  }

  static setDisplayedGlobalMessage(): void {
    sessionStorage.setItem(Keys.displayedGlobalMessage, "true");
  }

  static didDisplayGlobalMessage(): boolean {
    return sessionStorage.getItem(Keys.displayedGlobalMessage) === "true";
  }

  // Methods for form data
  static setFormData(formData: Record<string, string>): void {
    sessionStorage.setItem(Keys.FORM_DATA, JSON.stringify(formData));
  }

  static getFormData(): Record<string, string> | null {
    const data = sessionStorage.getItem(Keys.FORM_DATA);
    return data ? JSON.parse(data) : null;
  }

  static removeFormData() {
    sessionStorage.removeItem(Keys.FORM_DATA);
  }
}
