import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconProps,
  Typography,
  Box,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export interface Feature {
  icon?: React.ReactNode;
  description: string;
}

export interface OnboardingDataItem {
  title: string;
  description?: string[];
  footer?: string | null;
  features?: Feature[];
  imageSrc: string | (() => React.ReactElement);
}

interface Props {
  data: OnboardingDataItem[];
  finishLabel: string;
  onFinish: () => void;
}

const OnboardingComponent: React.FC<Props> = ({
  data,
  finishLabel,
  onFinish,
}) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === data.length - 1;

  const handleNext = () => {
    if (isLastStep) {
      onFinish();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderImageSrc = (imageSrc: string | (() => React.ReactElement)) => {
    if (typeof imageSrc === "string") {
      return (
        <CardMedia
          component="img"
          height="203"
          image={imageSrc}
          alt={data[activeStep].title}
          sx={{ objectFit: "contain", backgroundColor: "lightGray", py: 2.5 }}
        />
      );
    } else {
      return imageSrc();
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "calc(100vh - 56px - 32px)",
      }}
    >
      {renderImageSrc(data[activeStep].imageSrc)}
      <CardContent sx={{ flexGrow: 1, overflowY: "auto" }}>
        {data.length > 1 && (
          <Typography gutterBottom variant="caption" color="text.secondary">
            {t("onboarding_step", {
              currentStep: activeStep + 1,
              totalSteps: data.length,
            })}
          </Typography>
        )}

        <Typography gutterBottom variant="h5">
          {data[activeStep].title}
        </Typography>

        {data[activeStep].description?.map((description, index) => (
          <Typography key={index} variant="body2" color="text.primary">
            {description}
          </Typography>
        ))}

        {data[activeStep].features && (
          <List>
            {data[activeStep].features?.map((feature, index) => (
              <ListItem disableGutters key={index} alignItems="flex-start">
                {feature.icon && <ListItemIcon>{feature.icon}</ListItemIcon>}
                <ListItemText
                  primary={`${feature.icon ? "" : index + 1 + ". "}${
                    feature.description
                  }`}
                />
              </ListItem>
            ))}
          </List>
        )}

        {data[activeStep].footer && (
          <Typography variant="body2" color="text.primary">
            {data[activeStep].footer}
          </Typography>
        )}
      </CardContent>
      <Box
        sx={{
          flexGrow: 0,
          p: 2,
          width: "100%",
          borderTop: data.length > 1 ? "1px solid rgba(0, 0, 0, 0.12)" : "none",
        }}
      >
        {data.length > 1 ? (
          <CardActions sx={{ justifyContent: "space-between" }}>
            <Button
              startIcon={<KeyboardArrowLeftIcon />}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              {t("back")}
            </Button>
            <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleNext}>
              {isLastStep ? finishLabel : t("next")}
            </Button>
          </CardActions>
        ) : (
          <CardActions>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {finishLabel}
            </Button>
          </CardActions>
        )}
      </Box>
    </Card>
  );
};

export default OnboardingComponent;
