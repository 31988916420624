import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  List,
  ListItem,
  Typography,
} from "@mui/material";

interface ErrorBoxProps {
  title?: string;
  text: string;
  marginBottom?: number | string;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ title, text, marginBottom }) => {
  const renderMessages = () => {
    const messages = text.split("\n");
    if (messages.length > 1) {
      return (
        <List
          data-test-id="error-message"
          sx={{ listStyleType: "disc", paddingLeft: "12px" }}
        >
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              style={{
                paddingTop: "4px",
                paddingBottom: "4px",
                display: "list-item",
              }}
            >
              <Typography
                data-test-id={`error-message-${index}`}
                variant="body2"
              >
                {msg}
              </Typography>
            </ListItem>
          ))}
        </List>
      );
    } else {
      return (
        <Typography data-test-id="error-message" variant="body2">
          {text}
        </Typography>
      );
    }
  };
  return (
    <Alert
      severity="error"
      data-test-id="error-container"
      sx={{
        bgcolor: "error.main",
        color: "white",
        marginBottom: { marginBottom },
      }}
    >
      {title && (
        <AlertTitle>
          <Typography
            data-test-id="error-title"
            variant="subtitle2"
            sx={{ fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </AlertTitle>
      )}
      {renderMessages()}
    </Alert>
  );
};

export default ErrorBox;
