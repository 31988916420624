import { AxiosRequestConfig } from "axios";

export const termsAndConditionsStatus = (): AxiosRequestConfig => {
  return {
    url: "/v1/will-be-updated",
    method: "GET",
  };
};

export const acceptTermsAndConditions = (): AxiosRequestConfig => {
  return {
    url: "/v1/terms-and-conditions/accept",
    method: "PUT",
    data: {}
  };
};

export const rejectTermsAndConditions = (): AxiosRequestConfig => {
  return {
    url: "/v1/terms-and-conditions/reject",
    method: "PUT",
    data: {}
  };
};

// import apiInstance from './api'
// import {
//     TERMS_AND_CONDITIONS_REJECTED_URL,
//     TERMS_AND_CONDITIONS_URL,
//     TERMS_AND_CONDITIONS_ACCEPTED_URL,
//   } from "./config";

// interface TermsAndConditionsData {
//     is_update_available: boolean;
//   }

// export const areTAndCUpdated = async (): Promise<TermsAndConditionsData> => {
//   const api = apiInstance()
//     const response = await api.get(TERMS_AND_CONDITIONS_URL)
//     return response.data
//   };

//   export const acceptTAndCRequest = async (token: string): Promise<void> => {

//     const api = apiInstance(token)
//     const response = await api.put(TERMS_AND_CONDITIONS_ACCEPTED_URL)
//     return response.data
//   };

//   export const rejecTAndCRequest = async (token: string): Promise<void> => {
//     const api = apiInstance(token)
//     const response = await api.put(TERMS_AND_CONDITIONS_REJECTED_URL)
//     return response.data
//   };
