import { PaymentAccountDetails } from "./paymentTypes";

export interface StipendItem {
  amount?: number;
  claim_id?: number;
  visit_id?: number;
  rate_id?: number;
  id?: number;
  withholding_tax_percentage?: number;
  total_amount?: number;
}

export interface Stipend {
  id: number;
  state: string;
  pre_approved: string;
  notes: string;
  claimant_id: number;
  created_at: string;
  last_modified_at: string;
  approver_payment_status_changed_at: string;
  claimant_payment_status_changed_at?: string;
  reviewed_by: number;
  claim_owner: string;
  claim_type: string;
  payment_details?: PaymentAccountDetails;
  items?: StipendItem[];
  visit_id: number;
  amount?: number;
  total_amount?: number;
  withholding_tax_percentage?: number;
}

export interface DetailsItem {
  label: string;
  value: string | number | undefined;
}

export interface DetailsListProps {
  details: DetailsItem[];
}

interface StipendExtra {
  visit?: string;
}

export interface Payment {
  payee_id: string;
  transaction_id: string;
  amount: number;
  payment_reason: string | null;
  status: PaymentStatus;
  payment_account?: PaymentAccountDetails | null;
  extra?: StipendExtra;
  currency?: string | null;
  action_on?: string | null | undefined;
}

export enum PaymentStatus {
  MISSING_PAYMENT_DETAILS = "MISSING_PAYMENT_DETAILS",
  INVALID_PAYMENT_ACCOUNT = "INVALID_PAYMENT_ACCOUNT",
  QUEUED = "QUEUED",
  PROCESSING = "PROCESSING",
  SENT = "SENT",
  Issued = "Issued",
  VOIDED = "VOIDED",
}
