import React from "react";
import {
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatNumber } from "../../common/helpers";

interface TransactionItemProps {
  key: string;
  icon: React.ReactNode;
  title: string;
  description: string[];
  amount: number;
  currencySymbol?: string;
  linkTo: string;
}

const TransactionItem: React.FC<TransactionItemProps> = ({
  key,
  icon,
  title,
  description,
  amount,
  linkTo,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (linkTo) navigate(linkTo);
  };

  return (
    <>
      <ListItemButton
        disableGutters
        key={key}
        onClick={() => handleClick()}
        sx={{ display: "flex", alignItems: "center", pr: 1.5 }}
      >
        <Avatar sx={{ backgroundColor: "transparent", marginRight: 1 }}>
          {icon}
        </Avatar>
        <ListItemText
          primary={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{title}</Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  color: amount < 0 ? "red" : "green",
                }}
              >
                {formatNumber(amount, true)}
              </Typography>
            </Box>
          }
          secondary={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              {description.map((item, index) => (
                <Typography key={index} variant="body2" color="textSecondary">
                  {item}
                </Typography>
              ))}
            </Box>
          }
        />
      </ListItemButton>
    </>
  );
};

export default TransactionItem;
