import { Button, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleRedirect = () => {
    navigate("/payments");
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h1" data-test-id="not-found-code">
        404
      </Typography>
      <Typography variant="h2" data-test-id="not-found-title">
        {t("notFound_title")}
      </Typography>
      <Typography variant="body1" data-test-id="not-found-description">
        {t("notFound_description")}
      </Typography>
      <Box sx={{ marginTop: "2rem" }}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleRedirect}
          data-test-id="not-found-button"
        >
          {t("goHome")}
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
