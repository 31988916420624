import { loqateAPI } from "../api/api";
import {
  emailValidationService,
  phoneValidationService,
} from "../api/LoqateService";
import { ValidationStatus } from "./ValidationTypes";

// Caches for validated emails and phones
const emailValidationCache: { [email: string]: any } = {};
const phoneValidationCache: { [phone: string]: any } = {};

export const validateEmail = async (email: string) => {
  if (emailValidationCache[email]) {
    const responseData = emailValidationCache[email];
    return parseEmailResoonse(responseData);
  }

  try {
    const response = await loqateAPI(emailValidationService(email));
    emailValidationCache[email] = response.data;
    return parseEmailResoonse(response.data);
  } catch (error) {
    throw new Error("Error during email validation");
  }
};

export const validatePhone = async (phone: string, countryCode: string) => {
  if (phoneValidationCache[phone]) {
    const responseData = phoneValidationCache[phone];
    return parsePhoneResponse(responseData);
  }

  try {
    const response = await loqateAPI(
      phoneValidationService(phone, countryCode)
    );
    phoneValidationCache[phone] = response.data;
    return parsePhoneResponse(response.data);
  } catch (error) {
    throw new Error("Error during phone validation");
  }
};

const parsePhoneResponse = (responseData: any) => {
  const items = responseData.Items;
  if (items.length === 0) {
    return ValidationStatus.NOT_VALID;
  } else if (items[0].IsValid === "No") {
    return ValidationStatus.NOT_VALID;
  } else {
    return ValidationStatus.VALID;
  }
};

const parseEmailResoonse = (responseData: any) => {
  const items = responseData.Items;
  if (items.length === 0) {
    return ValidationStatus.NOT_VALID;
  } else if (items[0].ResponseCode === "Invalid") {
    return ValidationStatus.NOT_VALID;
  } else {
    return ValidationStatus.VALID;
  }
};
