import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";

import HtmlVccRenderer from "../components/VccCard/HtmlVccRenderer";
import { api } from "../api/api";
import paymentService from "../api/paymentService";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { Link } from "react-router-dom";

const styles = {
  listBackground: {
    background: "#fff",
  },
  lock: {
    background: "#fff",
    marginTop: "30px",
  },
};

const VccVisualise2: React.FC = () => {
  const [htmlCode, setHtmlCode] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();

  const isVccCreation = window.location.pathname === "/vcc-create";

  useEffect(() => {
    setLoading(true);

    api(paymentService.getVccPaymentVisualise())
      .then((response) => {
        setHtmlCode(response);
      })
      .catch((error) => {
        showBoundary(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Box width="100%">
      {loading ? (
        <>
          <Skeleton
            variant="rounded"
            height={184}
            style={{ marginBottom: 16 }}
          />

          {!isVccCreation && <Skeleton variant="rounded" height={72} />}
        </>
      ) : (
        <>
          {htmlCode && <HtmlVccRenderer htmlCode={htmlCode?.data} />}

          <List sx={styles.listBackground}>
            <ListItem>
              <ListItemIcon>
                <InfoOutlinedIcon color="disabled" />
              </ListItemIcon>

              <ListItemText primary={"About your card"} />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <SwapHorizOutlinedIcon color="disabled" />
              </ListItemIcon>

              <ListItemText primary={"Move money to your bank account"} />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <LocalAtmOutlinedIcon color="disabled" />
              </ListItemIcon>

              <ListItemText primary={"ATM cash pick-up"} />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemIcon>
                <EqualizerOutlinedIcon color="disabled" />
              </ListItemIcon>

              <ListItemText primary={"Balance and transaction history"} />
            </ListItem>
          </List>

          <List sx={styles.lock}>
            <ListItem>
              <ListItemIcon>
                <LockIcon color="error" />
              </ListItemIcon>

              <ListItemText primary={"Lock card"} />
            </ListItem>
          </List>

          <Link to={`/card/faq`}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginTop: 2 }}
              fullWidth
            >
              FAQ
            </Button>
          </Link>
        </>
      )}
    </Box>
  );
};

export default VccVisualise2;
