import {
  BankAccountData,
  ChequePaymentData,
  DebitCardData,
  PaymentAccountDetails,
  VccPaymentData,
} from "../types/paymentTypes";
import { api, Version } from "./api";
import {
  BASE_URL,
  VCC_PAYMENTS_URL,
  CVV_VISUALIZE_URL,
  LATEST_PAYMENT_URL,
  GET_PAYMENT_METHODS,
} from "./config";
import { AxiosRequestConfig } from "axios";

const paymentService = {
  getPaymentMethods: (): AxiosRequestConfig => {
    return {
      url: GET_PAYMENT_METHODS,
      method: "GET",
    };
  },
  createVccPayment: async (version: Version = "v2"): Promise<any> => {
    try {
      const response = await api({
        url: `/${version}/payment-methods/vc/latest-payment`,
        method: "POST",
        data: {},
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createPayment: (
    data: BankAccountData | ChequePaymentData | VccPaymentData | DebitCardData,
    endpoint: string
  ): AxiosRequestConfig => {
    return {
      url: endpoint,
      method: "POST",
      data: data,
    };
  },

  getVccPaymentVisualise: (version: Version = "v2"): AxiosRequestConfig => {
    return {
      url: `/${version}/payment-methods/vc/visualise`,
      method: "GET",
    };
  },
};

export default paymentService;
