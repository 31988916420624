import { Button, List, Typography } from "@mui/material";
import { transactionsByDate } from "./models";
import React from "react";
import TransactionCard from "./TransactionCard";
import { convertDateToFormattedString } from "../../common/helpers";

const styles = {
  list: {
    background: "#fff",
    padding: "10px",
  },
}

const AllTransactionsList: React.FC = () => {
  const formatDate = (date: string): string => {
    return convertDateToFormattedString(date);
  };

  return (
    <List sx={styles.list}>
      {transactionsByDate.map((transactionDate, index) => (
        <>
          <Typography key={index} variant="h6">{formatDate(transactionDate.date)}</Typography>

          {transactionDate.transactions.map((transaction) => (
            <React.Fragment key={transaction.id}>
              <TransactionCard transaction={transaction} />
            </React.Fragment>
          ))}
        </>
      ))}

      <Button
        variant="outlined"
        color="primary"
        sx={{ marginTop: 2 }}
        fullWidth
      >
        More
      </Button>
    </List>
  );
};

export default AllTransactionsList;
