import { Alert, AlertTitle, Typography } from "@mui/material";

interface Props {
  title: string;
  description: string;
  icon?: React.ReactNode;
  descriptionColor?: string;
  boldDescription?: boolean;
}

function PaymentDetailsRow({
  title,
  description,
  icon,
  descriptionColor,
  boldDescription = false,
}: Props) {
  return (
    <Alert
      data-test-id="row"
      variant="outlined"
      icon={icon}
      sx={{ width: "100%", border: "0px" }}
    >
      <AlertTitle>
        <Typography
          data-test-id={title}
          variant="subtitle2"
          color="text.secondary"
        >
          {title}
        </Typography>
      </AlertTitle>
      <Typography
        data-test-id={title + "-value"}
        variant="body2"
        color={descriptionColor ?? "text.primary"}
        style={{ overflowWrap: "anywhere" }}
        fontWeight={boldDescription ? "bold" : 400}
      >
        {description}
      </Typography>
    </Alert>
  );
}

export default PaymentDetailsRow;
