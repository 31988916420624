import React from "react";
import ErrorBox from "../components/Modal/ErrorBox";
import { Grid, Typography } from "@mui/material";
import { generateDeclinedDescription } from "../common/helpers";
import { useTranslation } from "react-i18next";
import { configService } from "../services/configService";

const Support = () => {
  const { t } = useTranslation();
  const supportEmail = configService.config.supportEmail;
  return (
    <Grid textAlign="left">
      <ErrorBox data-test-id="support-alert" text={t("help_contactHospital")} />
      <Typography
        data-test-id="support-title"
        variant="h6"
        component="h2"
        my={2}
      >
        {t("help_title")}
      </Typography>
      <Typography
        data-test-id="support-description"
        fontWeight="500"
        variant="body2"
      >
        {generateDeclinedDescription(
          t("help_contactSupport", { email: supportEmail })
        )}
      </Typography>
    </Grid>
  );
};
export default Support;
