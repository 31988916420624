interface AppConfig {
  supportEmail: string;
  showSupport: boolean;
}

class ConfigService {
  config: AppConfig;

  constructor() {
    this.config = {
      supportEmail: process.env.REACT_APP_SUPPORT_EMAIL ?? "",
      showSupport: process.env.REACT_APP_SHOW_SUPPORT === "true",
    };
  }
}

export const configService = new ConfigService();
