import React from "react";
import { Button, Link, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface FaqItem {
  question: string;
  answer?: string;
  children?: React.ReactNode;
}

const RenderList: React.FC<{ items: string[] }> = ({ items }) => {
  const { t } = useTranslation();

  return (
    <List
      sx={{
        py: 0,
        listStyleType: "disc",
        pl: 2,
        "& .MuiListItem-root": { display: "list-item" },
      }}
    >
      {items.map((itemKey, index) => (
        <ListItem key={index}>{t(itemKey)}</ListItem>
      ))}
    </List>
  );
};

const V1Q2Answer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <List
      sx={{
        py: 0,
        listStyleType: "disc",
        pl: 2,
        "& .MuiListItem-root": {
          display: "list-item",
        },
      }}
    >
      {Array.from({ length: 7 }, (_, index) => (
        <ListItem sx={{ px: 1 }} key={index}>
          {t(`vcc_faq_answer2_p${index + 1}`)}
        </ListItem>
      ))}
    </List>
  );
};

const V1Q3Answer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <List
      sx={{
        py: 0,
        listStyleType: "disc",
        pl: 2,
        "& .MuiListItem-root": {
          display: "list-item",
        },
      }}
    >
      {Array.from({ length: 7 }, (_, index) => (
        <ListItem sx={{ px: 1 }} key={index}>
          {t(`vcc_faq_answer3_p${index + 1}`)}
        </ListItem>
      ))}
    </List>
  );
};

export const cardV1faqData: FaqItem[] = [
  {
    question: "vcc_faq_question1",
    answer: "vcc_faq_answer1",
  },
  {
    question: "vcc_faq_question2",
    children: <V1Q2Answer />,
  },
  {
    question: "vcc_faq_question3",
    children: <V1Q3Answer />,
  },
];

export const cardV2faqData: FaqItem[] = [
  {
    question: "card_faq_google_wallet_question",
    children: (
      <RenderList
        items={[
          "card_faq_google_wallet_step1",
          "card_faq_google_wallet_step2",
          "card_faq_google_wallet_step3",
          "card_faq_google_wallet_step4",
          "card_faq_google_wallet_step5",
          "card_faq_google_wallet_step6",
        ]}
      />
    ),
  },
  {
    question: "card_faq_apple_wallet_question",
    children: (
      <RenderList
        items={[
          "card_faq_apple_wallet_step1",
          "card_faq_apple_wallet_step2",
          "card_faq_apple_wallet_step3",
          "card_faq_apple_wallet_step4",
          "card_faq_apple_wallet_step5",
          "card_faq_apple_wallet_step6",
        ]}
      />
    ),
  },
  {
    question: "card_faq_online_payments_question",
    children: (
      <RenderList
        items={[
          "card_faq_online_payments_step1",
          "card_faq_online_payments_step2",
          "card_faq_online_payments_step3",
        ]}
      />
    ),
  },
  {
    question: "card_faq_instore_payments_question",
    children: (
      <RenderList
        items={[
          "card_faq_instore_payments_step1",
          "card_faq_instore_payments_step2",
          "card_faq_instore_payments_step3",
        ]}
      />
    ),
  },
  {
    question: "card_faq_tracking_transactions_question",
    children: (
      <RenderList
        items={[
          "card_faq_tracking_transactions_step1",
          "card_faq_tracking_transactions_step2",
        ]}
      />
    ),
  },
];

export interface Transaction {
  id: number;
  description: string;
  amount: number;
  status:
    | "approved"
    | "insufficientFunds"
    | "refundIssued"
    | "transferCanceled";
}

export const transactions: Transaction[] = [
  {
    id: 1,
    description: "Somewhere supermarket",
    amount: -134.69,
    status: "approved",
  },
  {
    id: 2,
    description: "An Shops Ltd",
    amount: -6000.79,
    status: "insufficientFunds",
  },
  {
    id: 3,
    description: "Refund issued",
    amount: 60.59,
    status: "refundIssued",
  },
  {
    id: 4,
    description: "Bank transfer canceled",
    amount: -60.59,
    status: "transferCanceled",
  },
];

export const transactionsByDate: {
  date: string;
  transactions: Transaction[];
}[] = [
  {
    date: "10-10-2024",
    transactions: [
      {
        id: 1,
        description: "Somewhere supermarket",
        amount: -134.69,
        status: "approved",
      },
      {
        id: 2,
        description: "An Shops Ltd",
        amount: -6000.79,
        status: "insufficientFunds",
      },
      {
        id: 3,
        description: "Refund issued",
        amount: 60.59,
        status: "refundIssued",
      },
      {
        id: 4,
        description: "Bank transfer canceled",
        amount: -60.59,
        status: "transferCanceled",
      },
    ],
  },
  {
    date: "09-10-2024",
    transactions: [
      {
        id: 1,
        description: "Somewhere supermarket",
        amount: -134.69,
        status: "approved",
      },
      {
        id: 2,
        description: "An Shops Ltd",
        amount: -6000.79,
        status: "insufficientFunds",
      },
      {
        id: 3,
        description: "Refund issued",
        amount: 60.59,
        status: "refundIssued",
      },
      {
        id: 4,
        description: "Bank transfer canceled",
        amount: -60.59,
        status: "transferCanceled",
      },
    ],
  },
];
