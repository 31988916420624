import { configureStore, combineReducers } from "@reduxjs/toolkit";
import stipendReducer from "./slices/stipendSlice";
import vccReducer from "./slices/vccSlice";
import modalReducer from "./slices/modalSlice";
import toastReducer from "./slices/toastSlice";
import storage from "redux-persist/lib/storage";
import paymentMethodsReducer from "./slices/paymentMethodsSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import logger from "redux-logger";
import { CurriedGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import authSlice from "./slices/authSlice";
import cardSlice from "./slices/cardSlice";

export const RESET_STATE = "RESET_STATE";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["modal", "error"],
};

const rootReducer = combineReducers({
  paymentMethods: paymentMethodsReducer,
  stipend: stipendReducer,
  card: cardSlice,
  vcc: vccReducer,
  modal: modalReducer,
  toast: toastReducer,
  auth: authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

let middleware = (getDefaultMiddleware: CurriedGetDefaultMiddleware) => {
  let middlewareItems = [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ];
  // if (process.env.NODE_ENV === "development") {
  //   middlewareItems = [...middlewareItems, logger];
  // }
  return middlewareItems;
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => middleware(getDefaultMiddleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const reset = async () => {
  const purgePersistedState = await persistor.purge();
  const resetReduxState = await Promise.resolve(
    store.dispatch({ type: RESET_STATE })
  );
  await Promise.all([purgePersistedState, resetReduxState]);
};
