export interface PaymentMethodsResponse {
  has_failed_payments: boolean;
  country: string;
  latest_payment_account?: PaymentAccountDetails | null;
  available_payment_methods: AvailablePaymentMethod[];
}

export interface StatesList {
  values: StateItem[];
}

export interface StateItem {
  key: string;
  value: string;
}

export interface PaymentAccountDetails {
  target_account_id: string;
  target_account_type: string;
  target_account_title: string;
  target_account_identifier: string;
}

export interface PaymentMethodProp {
  enum?: { key: string; value: string }[];
  options?: StateItem[];
  property_name: string;
  title: string;
  description?: string;
  client_type?: string;
  minLength: number;
  maxLength: number;
  pattern?: string;
  properties?: Record<string, PaymentMethodProp>;
  nestedProps?: PaymentMethodProp[]; // Used to remap the "properties"
  type: string;
}

export interface AvailablePaymentMethod {
  title: string;
  type: string;
  properties: PaymentMethodProp[];
  required: string[];
  endpoint: string;
  payment_type: string;
  payment_country: string;
  country: string;
  currency: string;
}

export enum PaymentType {
  BankAccount = "BANK_ACCOUNT",
  DebitCard = "DEBIT_CARD",
  VirtualCardV1 = "V1_VIRTUAL_CARD",
  VirtualCardV2 = "V2_VIRTUAL_CARD",
  Check = "CHEQUE",
  Cash = "Cash pick up",
}

export enum PropertyClientType {
  MonthYear = "month-year",
  Number = "numeric",
  CardNumber = "card_number",
  Phone = "phone",
  Email = "email",
  Iban = "iban",
  Date = "date",
  Country = "country",
  State = "state",
  Address = "address",
  City = "city",
  ZIP = "zip",
  Line1 = "line_1",
}

export interface PaymentData {
  name: string;
  payment_country_iso: string;
}

export interface BankAccountData extends PaymentData {
  account: string;
  routing: string;
  type: string;
}

export interface DebitCardData extends PaymentData {
  card_number: string;
  expiration_date: string;
}

export interface ChequePaymentData extends PaymentData {
  email: string;
}

export interface VccPaymentData extends PaymentData {
  phone?: string;
  address?: any;
  state?: string;
}

// export interface PaymentMethodProps {
//   enum?: { key: string; value: string }[];
//   options?: any;
//   property_name: string;
//   title: string;
//   description?: string;
//   client_type?: string;
//   minLength: number;
//   maxLength: number;
//   pattern?: string;
//   properties?: Record<string, PaymentMethodProps>;
// }

export type VccData = {
  phone?: string;
  full_name: string;
  email?: string;
  payment_country_iso: any;
  address?: {
    line_1?: string;
    city?: string;
    zip?: string;
    country_code?: string;
    state?: string;
  };
};

export interface VirtualCardResponse {
  id: string;
  card_number: string;
  balance: number;
}

export interface ACHTransaction {
  amount: number;
  created_at: string;
  status: string;
  transaction_id: string;
  payment_account?: PaymentAccountDetails | null;
}

export interface CardBankWrapper {
  externalBankAccountDetails: CardBank;
}

export interface CardBank {
  last4: string;
  routingNumber: string;
  type: string;
}
