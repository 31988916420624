import React from "react";
import { ListItem, ListItemText, Divider } from "@mui/material";

interface Props {
  title: string;
  value: string;
}

const TitledValueListItem: React.FC<Props> = ({ title, value }) => {
  return (
    <ListItem disableGutters>
      <ListItemText
        primary={title}
        secondary={value}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textSecondary",
        }}
        secondaryTypographyProps={{
          variant: "body1",
          color: "textPrimary",
        }}
      />
    </ListItem>
  );
};

export default TitledValueListItem;
