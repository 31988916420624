import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface BankPaymentProcessingDialogProps {
  open: boolean;
  onClose: () => void;
}

const BankPaymentProcessingDialog: React.FC<
  BankPaymentProcessingDialogProps
> = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("bank_processing_title")}</DialogTitle>

      <DialogContent>
        <Typography variant="body2" color="text.primary" mb={1}>
          {t("bank_processing_description1")}
        </Typography>

        <Typography variant="body2" color="text.primary">
          {t("bank_processing_description2")}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("okGotIt")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BankPaymentProcessingDialog;
