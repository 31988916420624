// Stipend Slice

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Payment } from "../../types/stipendTypes";

export const RESET_STATE = "RESET_STATE";

type StipendState = {
  data: Payment[];
};

const initialState: StipendState = {
  data: [],
};

const stipendSlice = createSlice({
  name: "stipend",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<Payment[]>) => {
      state.data = action.payload;
    },
    addStipend: (state, action: PayloadAction<Payment>) => {
      state?.data?.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const { setData, addStipend } = stipendSlice.actions;

export default stipendSlice.reducer;
