import { createTheme, Theme } from "@mui/material/styles";

let primaryColor, secondaryColor, borderBottomRightRadiusValue;
let client = process.env.REACT_APP_CLIENT;

if (client === "velocity") {
  primaryColor = "#284497";
  secondaryColor = "#3FB44E";
  borderBottomRightRadiusValue = "40px";
} else {
  primaryColor = "#196392";
  secondaryColor = "#00008A";
  borderBottomRightRadiusValue = "0px";
}

const theme: Theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    error: {
      main: "#e54142",
    },
    success: {
      main: "#2e7d32",
    },
    info: {
      main: "#0288d1",
    },
    divider: "rgba(0, 0, 0, 0.12)",
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottomRightRadius: borderBottomRightRadiusValue,
          boxShadow: "none",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#f44336",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          "& .MuiAlert-icon": {
            color: "inherit",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& .MuiGrid-item": {
            maxWidth: "100%",
          },
        },
      },
    },
  },
});

export default theme;
