import { AxiosRequestConfig } from "axios";
import { Version } from "./api";

export const getPaymentMethods = (
  version: Version = "v2"
): AxiosRequestConfig => {
  return {
    url: `/${version}/payment-methods`,
    method: "GET",
  };
};

export const getVirtualCard = (version: Version = "v2"): AxiosRequestConfig => {
  return {
    url: `/${version}/payment-methods/vc`,
    method: "GET",
  };
};

export const getAchBankAccount = (
  version: Version = "v2"
): AxiosRequestConfig => {
  return {
    url: `/${version}/payment-methods/ach-bank-account`,
    method: "GET",
  };
};

export const getAchBankAccountSchema = (
  version: Version = "v2"
): AxiosRequestConfig => {
  return {
    url: `/${version}/payment-methods/ach-bank-account-schema`,
    method: "GET",
  };
};

export const makeTransferToBankAccount = (
  data: any,
  version: Version = "v2"
): AxiosRequestConfig => {
  return {
    url: `/${version}/payment-methods/make-ach-out`,
    method: "POST",
    data: data,
  };
};
