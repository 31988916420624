import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Alert, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAPI, Version } from "../../api/api";
import CardActions from "./components/CardActions";
import CardViewer from "./components/CardViewer";
import { CardProvider } from "./components/CardContext";
import { getAchBankAccount } from "../../api/paymentMethodsService";
import { useDispatch } from "react-redux";
import { resetTransferAmount, setCardBank } from "../../redux/slices/cardSlice";
import CardActionsSkeleton from "./components/CardActionsSkeleton";
import { CardBankWrapper } from "../../types/paymentTypes";

const CardDetailsPage: React.FC = () => {
  const { version } = useParams<{ version: string }>();
  const ver: Version = version === "1" ? "v1" : "v2";
  const { t } = useTranslation();
  // const { loading, data, error } = useAPI<CardBankWrapper>(getAchBankAccount());
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(resetTransferAmount());
  // }, []);

  // useEffect(() => {
  //   const bankDetails = data?.externalBankAccountDetails;
  //   if (!bankDetails) return;
  //   dispatch(setCardBank(bankDetails));
  // }, [data]);

  return (
    <CardProvider>
      <Box>
        {/* {ver === "v1" && oldCardWarning()} */}
        <CardViewer version={ver} />
        {/* {loading ? (
          <CardActionsSkeleton version={ver} />
        ) : ( */}
        <CardActions version={ver} />
        {/* )} */}
      </Box>
    </CardProvider>
  );

  function oldCardWarning() {
    return (
      <Alert
        variant="filled"
        color="error"
        sx={{ mb: 1.5 }}
        icon={<ErrorOutlineIcon fontSize="inherit" />}
        severity="info"
      >
        {t("cardOnboarding_description_oldCard_3")}
      </Alert>
    );
  }
};

export default CardDetailsPage;
