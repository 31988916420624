import { PaymentStatus } from "../../types/stipendTypes";
import i18next from "i18next";

export const statusSeverity = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.SENT:
      return "success";
    case PaymentStatus.QUEUED:
      return "info";
    case PaymentStatus.PROCESSING:
      return "info";
    case PaymentStatus.MISSING_PAYMENT_DETAILS:
      return "error";
    case PaymentStatus.INVALID_PAYMENT_ACCOUNT:
      return "error";
    default:
      return "info";
  }
};

export const getStatusText = (status: PaymentStatus) => {
  if (!status) return "";

  switch (status) {
    case PaymentStatus.MISSING_PAYMENT_DETAILS:
    case PaymentStatus.INVALID_PAYMENT_ACCOUNT:
      return i18next.t("payment_failed");
    case PaymentStatus.SENT:
      return i18next.t("payment_payed");
    case PaymentStatus.QUEUED:
      return i18next.t("payment_queued");
    case PaymentStatus.PROCESSING:
      return i18next.t("payment_processing");
    case PaymentStatus.VOIDED:
      return i18next.t("payment_voided");
    default:
      return toTitleCase(status);
  }
};

export function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
