export const BASE_URL = process.env.REACT_APP_NMIBLE_SERVER?.endsWith("/")
  ? process.env.REACT_APP_NMIBLE_SERVER.slice(0, -1)
  : process.env.REACT_APP_NMIBLE_SERVER;
export const EVERVAULT_BASE_URL = process.env.REACT_APP_EVERVAULT;
export const HELLO_FUNCTION = "/hello-function";

//v1
export const AUTH = "/auth";
export const LOGIN = "/payee-login";
export const REFRESH = "/payee-refresh";
export const PAYMENT_METHODS = "/payment-methods";
export const VC = "/vc";
export const BANK_ACCOUNT = "/bank-account";
export const DEBIT_CARD = "/debit-card";

export const ACCEPT = "/accept";
export const CLAIMANTS = "/claimants";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PAYMENTS = "/payments";
export const DECRYPTION_RUN_TOKEN = "/decryption-run-token/";
export const CLAIMS = "/claims";
export const TOKEN = "/token";
export const PARTICIPANT = "/participant";
export const REJECT = "/reject";

export const VISUALIZE = "/visualise";
export const LATEST_PAYMENT = "/latest-payment";
export const CHANGE_PIN = "/change-pin";
export const VERIFY_PIN = "/verify-pin";
export const WAITING_STIPENDS = "/waiting-stipends";
export const VISITS = "/visits";
export const STIPENDS = "/stipends";
export const APPROVE = "/approve";
export const PROFILE = "/profile";
export const STATES = "/states";

// v1 URLs
export const LOGIN_URL = BASE_URL + "/v1" + AUTH + LOGIN;
export const REFRESH_URL = BASE_URL + "/v1" + AUTH + REFRESH;
export const GET_PAYMENT_METHODS = BASE_URL + "/v1" + PAYMENT_METHODS;
export const BANK_ACCOUNT_PAYMENT_URL =
  BASE_URL + "/v1" + PAYMENT_METHODS + BANK_ACCOUNT;
export const DEBIT_CARD_PAYMENT_URL =
  BASE_URL + "/v1" + PAYMENT_METHODS + DEBIT_CARD;
export const VC_PAYMENT_URL = BASE_URL + "/v1" + PAYMENT_METHODS + VC;

// URLs
export const EVERVAULT_GET_NEW_TOKEN_URL = EVERVAULT_BASE_URL + HELLO_FUNCTION;
export const CLAIMANTS_PROFILE_URL = BASE_URL + CLAIMANTS + PROFILE;
export const DECRYPTED_PAYMENT_TOKEN_URL =
  BASE_URL + CLAIMANTS + PAYMENTS + DECRYPTION_RUN_TOKEN;
export const DECRYPTED_TOKEN_URL = BASE_URL + DECRYPTION_RUN_TOKEN;

export const TERMS_AND_CONDITIONS_URL = BASE_URL + TERMS_AND_CONDITIONS;
export const TERMS_AND_CONDITIONS_ACCEPTED_URL =
  TERMS_AND_CONDITIONS_URL + ACCEPT;
export const TERMS_AND_CONDITIONS_REJECTED_URL =
  TERMS_AND_CONDITIONS_URL + REJECT;

export const VCC_PAYMENTS_URL = BASE_URL + CLAIMANTS + PAYMENTS + VC;
export const CVV_VISUALIZE_URL =
  BASE_URL + CLAIMANTS + PAYMENTS + VC + VISUALIZE;
export const LATEST_PAYMENT_URL =
  BASE_URL + CLAIMANTS + PAYMENTS + LATEST_PAYMENT;

export const CHANGE_PIN_URL = BASE_URL + CLAIMANTS + CHANGE_PIN;
export const VERIFY_PIN_URL = BASE_URL + CLAIMANTS + VERIFY_PIN;

export const GET_SNIPPET_HISTORY_URL = BASE_URL + CLAIMANTS + CLAIMS;
export const APPROVE_STIPEND_URL = BASE_URL + CLAIMANTS + CLAIMS + STIPENDS;
export const WAITING_STIPENDS_URL =
  BASE_URL + CLAIMANTS + CLAIMS + WAITING_STIPENDS;

export const VISITS_URL = BASE_URL + CLAIMANTS + VISITS;

export const STATES_URL = BASE_URL + STATES;
