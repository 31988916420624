// Stipend Slice

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ACHTransaction, CardBank } from "../../types/paymentTypes";

export const RESET_STATE = "RESET_STATE";

type CardState = {
  transactions: ACHTransaction[];
  cardBank?: CardBank;
  transferAmount?: number;
};

const initialState: CardState = {
  transactions: [],
  cardBank: undefined,
  transferAmount: undefined,
};

const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    resetTransferAmount: (state, action: PayloadAction) => {
      state.transferAmount = undefined;
    },
    setTransferAmount: (state, action: PayloadAction<number>) => {
      state.transferAmount = action.payload;
    },
    setCardBank: (state, action: PayloadAction<CardBank>) => {
      state.cardBank = action.payload;
    },
    setTransactionsData: (state, action: PayloadAction<ACHTransaction[]>) => {
      state.transactions = action.payload;
    },
    addTransaction: (state, action: PayloadAction<ACHTransaction>) => {
      state?.transactions?.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const {
  setTransactionsData,
  addTransaction,
  setCardBank,
  setTransferAmount,
  resetTransferAmount,
} = cardSlice.actions;

export default cardSlice.reducer;
