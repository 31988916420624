import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { generateDeclinedDescription } from "../../common/helpers";
import { useTranslation } from "react-i18next";

interface ModalProps {
  setIsModalOpen: () => void;
  title?: string;
  modalMessage: string;
}

const InfoModal = ({ setIsModalOpen, title, modalMessage }: ModalProps) => {
  const { t } = useTranslation();
  return (
    <Box
      data-test-id="modal"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 9999,
      }}
    >
      <Paper sx={{ width: "80%" }} elevation={3}>
        <Box
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            minWidth: 300,
            minHeight: 200,
          }}
        >
          {title && (
            <Typography data-test-id="modal-title" variant="h6">
              {title}
            </Typography>
          )}
          <Typography
            data-test-id="modal-description"
            variant="body1"
            textAlign="left"
          >
            {generateDeclinedDescription(modalMessage)}
          </Typography>
          <Box sx={{ width: "100%", textAlign: "right" }}>
            <Button
              variant="text"
              size="large"
              onClick={() => setIsModalOpen()}
              sx={{ fontWeight: "bold" }}
              data-test-id="modal-button"
            >
              {t("okGotIt")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoModal;
