import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CardData,
  TransformedVccCardType,
  VccCardType,
} from "../../types/common";
import { PaymentType, VccData } from "../../types/paymentTypes";

type VccState = {
  vcc1Data: TransformedVccCardType | null;
  vcc2Data: TransformedVccCardType | null;
  vccFormData?: any;
};

export const RESET_STATE = "RESET_STATE";

const initialState: VccState = {
  vcc1Data: null,
  vcc2Data: null,
  vccFormData: undefined,
};

const vccSlice = createSlice({
  name: "VIRTUAL_CARD",
  initialState,
  reducers: {
    setCardData: (state, action: PayloadAction<CardData>) => {
      const cardData: TransformedVccCardType = {
        target_account_id: action.payload.target_account_id,
        target_account_type: action.payload.type,
        target_account_title: "Virtual card",
        target_account_identifier: action.payload.card_number,
        balance: action.payload.balance,
      };
      if (cardData.target_account_type === PaymentType.VirtualCardV1)
        state.vcc1Data = cardData;
      else state.vcc2Data = cardData;
    },
    setVccData: (state, action: PayloadAction<TransformedVccCardType>) => {
      if (action.payload.target_account_type === PaymentType.VirtualCardV1)
        state.vcc1Data = action.payload;
      else state.vcc2Data = action.payload;
    },
    setVccFormData: (state, action: PayloadAction<any>) => {
      state.vccFormData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RESET_STATE, () => {
      return initialState;
    });
  },
});

export const { setVccData, setCardData, setVccFormData } = vccSlice.actions;

export default vccSlice.reducer;
