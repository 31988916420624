import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Alert,
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useAPI } from "../api/api";
import {
  getAchBankAccountSchema,
  getPaymentMethods,
  getVirtualCard,
} from "../api/paymentMethodsService";
import {
  achTransactionsService,
  paymentsService,
} from "../api/stipendsServices";
import no_payments from "../assets/no_payments.svg";
import {
  formatNumber,
  getLocalizedMessage,
  savePaymentMethodsData,
} from "../common/helpers";
import TransactionsList from "../components/Payment/ACHTransactionsList";
import LatestPayment from "../components/Payment/LatestPayment";
import PaymentsList from "../components/Payment/PaymentsList";
import StipendCard from "../components/Stipend/StipendCard";
import { setTransactionsData } from "../redux/slices/cardSlice";
import { setData } from "../redux/slices/stipendSlice";
import theme from "../theme";
import {
  ACHTransaction,
  AvailablePaymentMethod,
  PaymentMethodProp,
  PaymentMethodsResponse,
  PaymentType,
  VirtualCardResponse,
} from "../types/paymentTypes";
import { Payment } from "../types/stipendTypes";
import CardAlert from "./Card/CardAlert";
import ManageCard from "./Card/ManageCard";
import { Box } from "@mui/system";
import { RootState } from "../redux/store";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();

  const {
    data: paymentsData,
    loading: loadingPayments,
    error: paymentsError,
  } = useAPI<Payment[]>(paymentsService(), []);

  // const {
  //   data: achData,
  //   loading: loadingACHTransactions,
  //   error: achError,
  // } = useAPI<ACHTransaction[]>(achTransactionsService(), []);

  const {
    data: oldCardData,
    loading: oldCCardLoading,
    error: oldCCardError,
  } = useAPI<VirtualCardResponse[]>(getVirtualCard("v1"));

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    error: paymentMethodsError,
  } = useAPI<PaymentMethodsResponse | null>(getPaymentMethods());
  const navigate = useNavigate();

  // const {
  //   data: achBankAccountData,
  //   loading: loadingAchBankAccountData,
  //   error: errorAchBankAccount,
  // } = useAPI<AvailablePaymentMethod | null>(getAchBankAccountSchema());

  const onlyVCIsAllowed: boolean = useSelector((state: RootState) => {
    const paymentMethods =
      state.paymentMethods.data?.available_payment_methods ?? [];

    if (
      paymentMethods.length === 1 &&
      paymentMethods[0].payment_type === PaymentType.VirtualCardV2
    ) {
      return true;
    }
    return false;
  });

  function updatePropertyNameForChildren(
    obj: PaymentMethodProp
  ): PaymentMethodProp {
    if (!obj.properties) return obj;

    const updatedProperties = Object.entries(obj.properties).reduce(
      (acc, [key, value]) => {
        // Create a shallow copy of value
        const valueCopy = { ...value };

        // Setting the key as property_name for the copied object
        valueCopy.property_name = key;

        // Recursive call to handle further nesting
        acc[key] = updatePropertyNameForChildren(valueCopy);

        return acc;
      },
      {} as Record<string, PaymentMethodProp>
    );

    return { ...obj, properties: updatedProperties };
  }

  const paymentsMessage = getLocalizedMessage(
    process.env.REACT_APP_PAYMENTS_MESSAGE
  );

  useEffect(() => {
    if (!paymentMethodsData) {
      return;
    }

    savePaymentMethodsData(paymentMethodsData, dispatch);
    // will never happen because all users must have a card account
    // if (paymentMethodsData?.latest_payment_account === null) {
    //   navigate("/payment-methods?isFirstTime=true", { replace: true });
    // }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (!paymentMethodsData) {
      return;
    }

    const newPaymentMethodsData = JSON.parse(
      JSON.stringify(paymentMethodsData)
    );

    savePaymentMethodsData(newPaymentMethodsData, dispatch);
    // will never happen because all users must have a card account
    // if (paymentMethodsData?.latest_payment_account === null) {
    //   navigate("/payment-methods?isFirstTime=true", { replace: true });
    // }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (!paymentsError) {
      return;
    }
    showBoundary(paymentsError);
  }, [paymentsError]);

  useEffect(() => {
    if (!paymentMethodsError) {
      return;
    }

    showBoundary(paymentMethodsError);
  }, [paymentMethodsError]);

  useEffect(() => {
    dispatch(setData(paymentsData ?? []));
  }, [paymentsData]);

  // useEffect(() => {
  //   dispatch(setTransactionsData(achData ?? []));
  // }, [achData]);

  function paymentAccountSkeleton() {
    return (
      <Skeleton
        data-test-id="payment-account-skeleton"
        variant="rounded"
        height={96}
        style={{ marginBottom: "10px" }}
      />
    );
  }

  function paymentRowsSkeleton() {
    return (
      <Paper data-test-id="payments-skeleton" elevation={0}>
        <Skeleton
          variant="rounded"
          height={88}
          style={{ marginBottom: "10px" }}
        />
      </Paper>
    );
  }

  function paymentsSectionTitleSkeleton() {
    return (
      <Skeleton
        variant="text"
        height={40}
        width={"30%"}
        sx={{
          mt: 2,
          mb: 1,
        }}
      />
    );
  }

  function oldCardAlertSkeleton() {
    return <Skeleton variant="rounded" height={76} sx={{ mb: 1.5 }} />;
  }

  // const cardActivitiesSectionTitle = (title: string) => {
  //   if (isLoading()) return paymentsSectionTitleSkeleton();
  //   if ((achData ?? []).length === 0) return null;
  //   return sectionTitle(title);
  // };

  const paymentsSectionTitle = (title: string) => {
    if (isLoading()) return paymentsSectionTitleSkeleton();
    return sectionTitle(title);
  };

  const sectionTitle = (title: string) => {
    return (
      <Typography
        sx={{
          mt: 2,
          mb: 1,
        }}
        variant="subtitle2"
        color="text.secondary"
        fontWeight="600"
      >
        {title}
      </Typography>
    );
  };

  return (
    <Paper
      elevation={0}
      style={{
        backgroundColor: "transparent",
        minHeight: "calc(100vh - 56px - 32px)",
        paddingBottom: "50px",
      }}
    >
      {/* Alerts */}
      {paymentsMessage && <Alert severity="warning">{paymentsMessage}</Alert>}
      {paymentMethodsData?.has_failed_payments && failedPaymentAlert()}

      {oldCardAlert()}

      {/* New Card Balance */}
      {paymentMethodsData?.available_payment_methods.some(
        (item) => item.payment_type === PaymentType.VirtualCardV2
      ) && <ManageCard />}

      {/* Card  Transactions */}
      {/* {cardActivitiesSectionTitle(t("Card activity"))} */}
      {/* {isLoading()
        ? paymentRowsSkeleton()
        : (achData ?? []).length > 0 && (
            <TransactionsList transactions={achData ?? []} />
          )} */}

      {/* Payments */}
      {paymentsSectionTitle(t("payments"))}
      {isLoading()
        ? paymentRowsSkeleton()
        : paymentsData?.length === 0
        ? emptyData()
        : paymentRows()}

      {/* Payment Method */}
      {!onlyVCIsAllowed && paymentsSectionTitle(t("paymentMethod_title"))}
      {paymentAccount(paymentMethodsData)}
    </Paper>
  );

  function isLoading() {
    return loadingPayments || oldCCardLoading; // || loadingACHTransactions
  }

  function paymentAccount(paymentMethodsData: PaymentMethodsResponse | null) {
    if (paymentMethodsLoading) return paymentAccountSkeleton();
    if (!paymentMethodsData?.latest_payment_account || onlyVCIsAllowed)
      return null;
    return (
      <LatestPayment
        paymentAccount={paymentMethodsData.latest_payment_account}
        failedPayment={paymentMethodsData.has_failed_payments}
      />
    );
  }

  function oldCardAlert() {
    if (oldCCardLoading) return oldCardAlertSkeleton();
    if (!oldCardData || oldCardData.length === 0) return null;
    // if (oldCardData[0].balance == 0) return null;
    // return <CardAlert balance={oldCardData[0].balance ?? 0} />;

    return (
      <>
        <Typography color="text.secondary" variant="subtitle2" fontWeight="600">
          {t("vcc_yourCard")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            padding: 0,
            mt: 1,
            mb: "10px",
            cursor: "pointer",
            borderRadius: 1,
            border: "1px solid #0000001F",
            "& *": {
              cursor: "pointer",
            },
          }}
          onClick={() => navigate("/card/details/1")}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            p={1}
            alignItems="center"
          >
            <ListItem dense disableGutters sx={{ padding: 0 }}>
              <CreditCardRoundedIcon
                data-test-id="vcc-icon"
                sx={{ fontSize: 30 }}
                style={{ color: "gray" }}
              />
              <ListItemText
                sx={{ cursor: "default", textAlign: "left", ml: 1 }}
                data-test-id="vcc-balance"
                primary={
                  <Typography variant="subtitle2" fontWeight="600">
                    {t("vcc_availableBalance")}
                  </Typography>
                }
                secondary={
                  <Typography
                    data-test-id="vcc-balance-title"
                    variant="body2"
                    color="text.secondary"
                  >
                    {oldCardData[0]
                      ? `${formatNumber(oldCardData[0].balance)}`
                      : `$ 0.00`}
                  </Typography>
                }
              />
              <KeyboardArrowRightIcon color="action" />
            </ListItem>
          </Box>
        </Box>
      </>
    );
  }

  function failedPaymentAlert() {
    return (
      <StipendCard
        icon={<ErrorOutlineIcon />}
        title={t("failedPaymentWarning_title")}
        description={t("failedPaymentWarning_description")}
        color="white"
        backgroundColor="#D32F2F"
        linkTo="/payment-methods?failedPayment=true"
      />
    );
  }

  function paymentRows() {
    return <PaymentsList payments={paymentsData ?? []} />;
  }

  function emptyData() {
    return (
      <Card variant="outlined" sx={{ border: "none" }}>
        <CardContent>
          <Typography
            variant="body1"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "25vh",
              textAlign: "center",
            }}
          >
            {t("payments_empty")}
          </Typography>
        </CardContent>
      </Card>
    );
  }
};

export default PaymentsPage;
