import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import VCC from "../assets/VCC.svg";
import { useTranslation } from "react-i18next";

const VccCardPreview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const imgAspectRatioPadding = "56.25%";
  return (
    <div>
      <div
        style={{
          width: "100%",
          paddingTop: imgAspectRatioPadding,
          position: "relative",
        }}
      >
        <img
          data-test-id="vcc-preview-image"
          src={VCC}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          alt="virtual card preview"
        />
      </div>
      <Typography
        variant="h6"
        component="h6"
        fontWeight="500"
        color="text.secondary"
        textAlign="left"
        pt="20px"
        pb="20px"
        data-test-id="vcc-preview-title"
      >
        {t("vcc_preview_title")}
      </Typography>
      <Typography
        data-test-id="vcc-preview-description"
        fontSize="14px"
        color="text.primary"
        pb="20px"
      >
        {t("vcc_preview_description")}
      </Typography>
      <Button
        data-test-id="vcc-preview-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<AddCardIcon />}
        onClick={() => navigate("/vcc-setup")}
        style={{ textTransform: "uppercase" }}
      >
        {t("vcc_create")}
      </Button>
    </div>
  );
};

export default VccCardPreview;
