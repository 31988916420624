import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import InfoModal from "../components/Modal/InfoModal";
import { api } from "../api/api";
import {
  acceptTermsAndConditions,
  rejectTermsAndConditions,
} from "../api/termsAndConditionsServices";
import { Container, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { showError } from "../redux/slices/toastSlice";
import { getErrorDetails } from "../common/CustomErrorBoundary";
import { AxiosError } from "axios";
import { configService } from "../services/configService";
import { getTermsAndConditionsLinks } from "../common/TermsAndConditionsContentProvider";
import { enterApp } from "../common/helpers";

const TermsAndConditions = () => {
  const [displayTAndC, setDisplayTAndC] = React.useState(true);
  const [isDeclined, setIsDeclined] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [isAccepting, setIsAccepting] = React.useState(false);
  const [isDeclining, setIsDeclining] = React.useState(false);
  const supportEmail = configService.config.supportEmail;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleError = (error: AxiosError) => {
    const errorDetails = getErrorDetails(error, t);
    if (errorDetails) {
      dispatch(
        showError({
          title: errorDetails.props.title,
          message: errorDetails.props.message,
        })
      );
    }
  };

  const handleDecline = () => {
    setIsDeclining(true);
    api(rejectTermsAndConditions())
      .then(() => {
        setDisplayTAndC(!displayTAndC);
        setIsModalOpen();
        setIsDeclined(!isDeclined);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        setIsDeclining(false);
      });
  };

  const handleAccept = () => {
    setIsAccepting(true);
    api(acceptTermsAndConditions())
      .then(() => {
        enter();
      })
      .catch((error) => {
        setIsAccepting(false);
        handleError(error);
      });
  };

  function enter() {
    enterApp(navigate, api, dispatch);
  }

  return (
    <Container>
      <Grid
        container
        spacing={2}
        style={{
          minHeight: "calc(100vh - 100px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          item
          style={{ flex: "1 1 auto", width: "100%", position: "relative" }}
        >
          <iframe
            data-test-id="tc-iframe"
            src={getTermsAndConditionsLinks().content}
            title="Terms and Conditions"
            width="100%"
            height="100%"
            style={{
              borderStyle: "ridge",
              position: "absolute",
              top: "10px",
              left: "8px",
            }}
          />
        </Grid>
        <Grid item style={{ flex: "0 0 auto" }}>
          <Stack spacing={2} justifyContent="center">
            {!isDeclined && (
              <LoadingButton
                data-test-id="tc-decline-button"
                onClick={handleDecline}
                size="large"
                variant="text"
                sx={{ color: "gray" }}
                loading={isDeclining}
              >
                <span>{t("terms_decline")}</span>
              </LoadingButton>
            )}
            <LoadingButton
              data-test-id="tc-accept-button"
              onClick={handleAccept}
              size="large"
              variant="contained"
              color="primary"
              sx={{ fontWeight: "bold" }}
              loading={isAccepting}
            >
              <span>{t("terms_accept")}</span>
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      {isModalOpen && (
        <InfoModal
          setIsModalOpen={setIsModalOpen}
          modalMessage={t("terms_declinedMessage", { email: supportEmail })}
        />
      )}
    </Container>
  );
};

export default TermsAndConditions;
