import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import VccVisualise from "./VccVisualisePage";
import { showToast } from "../redux/slices/toastSlice";
import { useTranslation } from "react-i18next";

function VccCardCreated() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <VccVisualise />
      <Grid sx={{ width: "100%", mb: "1rem" }}>
        <Typography
          data-test-id="vcc-created-title"
          variant="h6"
          component="h6"
          fontWeight="500"
          color="text.secondary"
          textAlign="left"
          pt="30px"
        >
          {t("vcc_created_title")}
        </Typography>
        <Typography
          data-test-id="vcc-created-description"
          variant="body2"
          textAlign="left"
        >
          {t("vcc_created_description")}
        </Typography>
      </Grid>

      <Button
        data-test-id="vcc-created-button"
        fullWidth
        variant="contained"
        size="large"
        startIcon={<CheckCircleIcon />}
        onClick={() => {
          dispatch(showToast(t("paymentMethod_updated")));
          navigate("/payments");
        }}
      >
        {t("okGotIt")}
      </Button>
    </>
  );
}

export default VccCardCreated;
