import { AxiosRequestConfig } from "axios";

export const refreshTokenService = (
  refreshToken: string
): AxiosRequestConfig => {
  return {
    url: "/v1/auth/payee-refresh",
    method: "POST",
    data: {
      refresh_token: refreshToken,
    },
  };
};

export const loginService = (token: string): AxiosRequestConfig => {
  return {
    url: "/v1/auth/payee-login",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
