import React, { createContext, useContext, useRef, useState } from "react";

interface CardContextProps {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  toggleCardNumberMask: () => void;
  cardVisible: boolean;
  setCardVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardContext = createContext<CardContextProps | undefined>(undefined);

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error("useCardContext must be used within a CardProvider");
  }
  return context;
};

export const CardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(document.createElement("iframe"));
  const [cardVisible, setCardVisible] = useState(false);

  const toggleCardNumberMask = () => {
    iframeRef.current?.contentWindow?.postMessage("toggleCardNumberMask", "*");
    setCardVisible((prev) => !prev);
  };

  return (
    <CardContext.Provider
      value={{ iframeRef, toggleCardNumberMask, cardVisible, setCardVisible }}
    >
      {children}
    </CardContext.Provider>
  );
};
