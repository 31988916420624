import React from "react";
import { Typography, Icon, List, ListItem, Divider } from "@mui/material";

interface ListItemProps {
  icon: React.ReactNode;
  text: string;
}

interface IconListProps {
  items: ListItemProps[];
}

const IconList: React.FC<IconListProps> = ({ items }) => {
  return (
    <List style={{ overflowY: "auto" }}>
      {items.map((item, idx) => (
        <React.Fragment key={`list-item-${idx}`}>
          <ListItem
            style={{ display: "flex", alignItems: "center", padding: "10px 0" }}
          >
            <Icon
              data-test-id="listitem-icon"
              color="action"
              style={{ marginRight: "25px" }}
            >
              {item.icon}
            </Icon>
            <Typography
              data-test-id="listitem-text"
              fontSize="14px"
              color="text.primary"
              textAlign="left"
            >
              {item.text}
            </Typography>
          </ListItem>
          {idx !== items.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

export default IconList;
