import React, { useState } from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import BarChartIcon from "@mui/icons-material/BarChart";
import { api, Version } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useCardContext } from "./CardContext";
import { getAchBankAccount } from "../../../api/paymentMethodsService";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

const CardActions: React.FC<{ version: Version }> = ({ version }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ver = version === "v1" ? "1" : "2";
  const { cardVisible, toggleCardNumberMask } = useCardContext();
  // const cardBank = useSelector((state: RootState) => state.card.cardBank);

  // function handleTransferMoney() {
  //   if (cardBank) {
  //     navigate("/transfer");
  //   } else {
  //     navigate("/payment-methods/BANK_ACCOUNT/onboarding");
  //   }
  // }

  return (
    <>
      <List sx={{ mt: 2, backgroundColor: "white" }}>
        {version === "v2" && (
          <>
            <ListItemButton onClick={toggleCardNumberMask}>
              <ListItemIcon>
                {cardVisible ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={cardVisible ? t("card_hide") : t("card_show")}
              />
            </ListItemButton>
            <Divider />
          </>
        )}

        <ListItemButton onClick={() => navigate(`/card/${ver}/faq`)}>
          <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={t("card_about_your_card")} />
        </ListItemButton>
      </List>

      {version === "v2" && (
        <section>
          <Typography mt={3} mb={1} variant="subtitle2" color="text.secondary">
            {t("card_upcoming_features")}
          </Typography>

          <List sx={{ backgroundColor: "white" }}>
            <ListItemButton disabled>
              <ListItemIcon>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText primary={t("card_move_money")} />
            </ListItemButton>
            <Divider />
            <ListItemButton disabled>
              <ListItemIcon>
                <AccountBalanceWalletOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("card_atm_pickup")} />
            </ListItemButton>
            <Divider />
            <ListItemButton disabled>
              <ListItemIcon>
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary={t("card_balance_history")} />
            </ListItemButton>
            <Divider />
            <ListItemButton disabled>
              <ListItemIcon>
                <LockOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={t("card_lock_card")} />
            </ListItemButton>
          </List>
        </section>
      )}
    </>
  );
};

export default CardActions;
